import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import log from "Utils/logger";

import Tabs from 'SharedComponents/tabs';
import SignInForm from 'SharedComponents/sign-in-form';
import ShareStrip from 'SharedComponents/share-strip';
import ContactInfoForm from 'SharedComponents/contact-info-form';
import PasswordForm from './PasswordForm';
import ActivityLog from './ActivityLog';

import style from './index.css';

import useFetchActivityLogs from 'SharedHooks/fetch-activity-logs';
import useFetchRoundUpsShopperData from 'SharedHooks/fetch-roundups-shopper-data';

const AccountWrapper = styled.div`${style}`;

const Account = () => {
  const [activeTab, setActiveTab] = useState('about-you');
  const authentication = useSelector((state) => state.authentication);
  const { isFetchingActivityLogs, fetchActivityLogs } = useFetchActivityLogs(); // Use the custom hook
  const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
  console.log ('**Account function triggered with authentication:', authentication);

  useEffect(() => {
    console.log('**Account useEffect triggered - HELLO');
    if (authentication && authentication.user.shopper_id && !isFetchingActivityLogs) { 
      console.log('**Account useEffect about to fetchActivityLogs');
      fetchActivityLogs();
    }
  }, [authentication.user.shopper_id ]); 

  const onActiveTabChanged = (newActiveTab) => setActiveTab(newActiveTab);

  if (!authentication.user) {
    return (
      <AccountWrapper>
        <div style={{ maxWidth: 600, padding: 10, width: 'calc(100% - 20px)', height: '100%' }}>
          <SignInForm
            showEmailLogin
            header={(
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <h1>Login</h1>
                <span>To access your account</span>
              </div>
            )}
            showSocialLogin
          />
        </div>
      </AccountWrapper>
    );
  }

  return (
    <AccountWrapper>
      <ShareStrip />
      <div className="content">
        <Tabs
          onActiveTabChanged={onActiveTabChanged}
          activeTab={activeTab}
          tabs={[
            {
              label: 'About You',
              value: 'about-you',
            },
            {
              label: 'Account History',
              value: 'activity-log',
            }
          ]}
        />
        {activeTab === 'about-you' && (
          <div style={{ width: '100%' }}>
            <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: '100%' }}>
              <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                  <ContactInfoForm />
                </Col>
                {authentication.user.signup_method === 'email' && (
                  <Col xl={6} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                    <PasswordForm />
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        )}
        {activeTab === 'activity-log' && (
          <div style={{ width: 'calc(100% - 20px)', padding: 10 }}>
            <ActivityLog
              activityLogs={authentication.activityLogs} 
              isFetchingActivityLogs={isFetchingActivityLogs} 
            />
          </div>
        )}
      </div>
    </AccountWrapper>
  );
};

export default Account;


