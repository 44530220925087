import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import styles from './store.styles.js';

import Favorite from './favorite'
import StoreBoostsList from 'SharedComponents/store-boosts-list';

const StoreWrapper = styled.div`${styles}`;

const Store = ({ store, onShopNow, onSetSharedItem, setFavShops }) => {

  return (
    <StoreWrapper store={store}>
      <div className="store-card-container">

        <div className="store-header">
          {
            parseInt(store.deals_count) ? (
              <div className="deals-container">
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <span>&nbsp;&nbsp;{`${store.deals_count} ${parseInt(store.deals_count) === 1 ? 'offer' : 'offers'} available`}&nbsp;&nbsp;</span>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
              </div>
            ) : null
          }
        </div>
        <button type="button" onClick={() => onShopNow(store, 'store')} className="store-image-container"></button>
        
        <div className="store-name-container">
          <small className="store-name">{store.merchant_name}</small>
        </div>

        <div className="fav-button">
          <Favorite 
            favorite={store.favorite} 
            setFavShops={setFavShops}
            retailStoreId={store.retail_store_id}
            />      
        </div>

        <div className="store-details-container">
          <div className="store-rate">
            {/* {store.rate} */}
            <StoreBoostsList
                retailStore={store}
                retailStoreId={store.retail_store_id}
              />
          </div>
          <div className="actions-container">
            <button className="more-info" onClick={() => onSetSharedItem(store, 'store')}>
              <i className="material-icons">info</i>
              <span>info</span>
            </button>
            <button className="shop-now" onClick={() => onShopNow(store, 'store')}>Shop Now</button>
          </div>
        </div>
      </div>
    </StoreWrapper>
  );
}

export default Store;
