import React, { useState, useEffect, useCallback, useRef } from 'react';
import { PieChart, Pie, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from 'react-spinner-material';
import { BarLoader } from 'react-spinners';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-grid-system';
//import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { colors } from '@mui/material';
import CountUp from 'react-countup';

import PopoverHover from 'SharedComponents/popover-hover';
import ActionButton from 'SharedComponents/action-button';
import ModalDialogContributionsFees from 'SharedComponents/modal-dialog-contributions-fees';
import Formsy from "formsy-react";
import TextInput from "SharedComponents/text-input";
import DashboardCard from 'SharedComponents/dashboard-card';
import GraphCalculatorBalancePie from 'SharedComponents/graph-calculator-balance';
import GraphTotalBoostAmounts from 'SharedComponents/graph-total-amounts';
import GraphCalculatorGrowthChart from 'SharedComponents/graph-calculator-growth-chart';
import GraphStackedBar from 'SharedComponents/graph-stacked-bar';
import MyInput, { isRequired } from 'SharedComponents/text-input';
import DropDownSelect from 'SharedComponents/dropdown-select';

import log from "Utils/logger";

import styles from './index.css.js';
import * as CalculatorActions from "Flux/calculators/actions";
import { current } from '@reduxjs/toolkit';
import { set } from 'lodash';

//look to convert to useHooks
// import { getCalculatorData, saveCalculatorData, getDefaultCalculatorCashbackVariables } from "Flux/calculators/actions";
// import { first } from 'lodash';

const CalculatorWrapper = styled.section`${styles}`;

const SuperCalculator = () => {
  
  const navigate = useNavigate();
  //const app = useSelector((state) => state.app); // this is only being used for accent color kill it
  const altAccentColor = useSelector ((state) => state.app.partnerContent.alternate_accent_color);

  const calculators = useSelector((state) => state.calculators);
  const user = useSelector((state) => state.authentication.user);
  const dispatch = useDispatch();

  const [showModalDialogContributionsFees, setShowModalDialogContributionsFees] = useState(false);
  const [activeContentComponent, setActiveContentComponent] = useState(null);
  
  const [age, setAge] = useState(calculators.age);
  const [gender, setGender] = useState(calculators.gender);
  const [desiredRetirementAge, setDesiredRetirementAge] = useState(calculators.desired_retirement_age);
  const [income, setIncome] = useState(calculators.income);
  const [currentSuperBalance, setCurrentSuperBalance] = useState(calculators.current_super_balance);
  const [investmentReturn, setInvestmentReturn] = useState(calculators.pa_investment_return);
  const [inflation, setInflation] = useState(calculators.inflation);
  const [feesAmount, setFeesAmount] = useState(parseFloat(calculators.superfund_fees_pa));
  const [feesPercentage, setFeesPercentage] = useState(calculators.superfund_fees_pc);

  const [employerContributionsPercentage, setEmployerContributionsPercentage] = useState(0.12);

  const [beforeTaxContributionAmt, setBeforeTaxContributionAmt] = useState(calculators.before_tax_contributions || 0);
  const [beforeTaxContributionFrq, setBeforeTaxContributionFrq] = useState(calculators.before_tax_contributions_frequency || 'M');
  const [beforeTaxContributionRes, setBeforeTaxContributionRes] = useState(0);
  
  const [roundUpsAfterTaxContributionAmt, setRoundUpsAfterTaxContributionAmt] = useState(
    calculators.round_ups_after_tax_contributions ||  40
  );
  const [roundUpsAfterTaxContributionFrq, setRoundUpsAfterTaxContributionFrq] = useState(
    calculators.round_ups_after_tax_contributions_frequency || 'M'
  );
  const [roundUpsAfterTaxContributionRes, setRoundUpsAfterTaxContributionRes] = useState(480);
  
  const [personalAfterTaxContributionAmt, setPersonalAfterTaxContributionAmt] = useState(calculators.personal_after_tax_contributions || 0);
  const [personalAfterTaxContributionFrq, setPersonalAfterTaxContributionFrq] = useState(calculators.personal_after_tax_contributions_frequency || 'M');
  const [personalAfterTaxContributionRes, setPersonalAfterTaxContributionRes] = useState(0);
  
  const [otherAfterTaxContributionAmt, setOtherAfterTaxContributionAmt] = useState(calculators.other_after_tax_contributions || 0);
  const [otherAfterTaxContributionFrq, setOtherAfterTaxContributionFrq] = useState(calculators.other_after_tax_contributions_frequency || 'M');
  const [otherAfterTaxContributionRes, setOtherAfterTaxContributionRes] = useState(0);

  const [vData, setVData] = useState({});
  const [cashbackAmts, setCashbackAmts] = useState({});
  const [cashbackFrqs, setCashbackFrqs] = useState({});
  const [cashbackAveAmts, setCashbackAveAmts] = useState({});
  const [cashbackResults, setCashbackResults] = useState({});

  const [sliderValues, setSliderValues] = useState([0, 16, 21.33, 32, 64]);
  const [sliderIndex, setSliderIndex] = useState(1);
  const [energyAmt, setEnergyAmt] = useState(sliderValues[0]);

  const retirementAgeTickMarks = [60, 65, 70, 75];

  const [isResetInitialCashbackDefalutsDone, setIsResetInitialCashbackDefalutsDone] = useState(false);

  const [roundUpsFeesPerMonth, setRoundUpsFeesPerMonth] = useState(4.40);

  const [resultRoundUpFees, setResultRoundUpFees] = useState(0);
  const [resultSuperBalanceNoBoost, setResultSuperBalanceNoBoost] = useState(0);
  const [resultSuperBalanceBeforeTaxBoostOnly, setResultSuperBalanceBeforeTaxBoostOnly] = useState(0);
  const [resultSuperBalanceAfterTaxBoostOnly, setResultSuperBalanceAfterTaxBoostOnly] = useState(0);
  const [resultSuperBalanceCashbackBoostOnly, setResultSuperBalanceCashbackBoostOnly] = useState(0);
  const [resultSuperBalancePercentageSpendBoostOnly, setResultSuperBalancePercentageSpendBoostOnly] = useState(0);
  const [resultSuperBalanceTotalBoost, setResultSuperBalanceTotalBoost] = useState(0);  
  const [resultBoostedByAmountBeforeTaxBoostOnly, setResultBoostedByAmountBeforeTaxBoostOnly] = useState(0);
  const [resultBoostedByAmountAfterTaxBoostOnly, setResultBoostedByAmountAfterTaxBoostOnly] = useState(0);
  const [resultBoostedByAmountCashbackBoostOnly, setResultBoostedByAmountCashbackBoostOnly] = useState(0);
  const [resultBoostedByAmountPercentageSpendOnly, setResultBoostedByAmountPercentageSpendOnly] = useState(0);
  const [resultBoostedByAmountTotalBoost, setResultBoostedByAmountTotalBoost] = useState(0);

  const [tableData, setTableData] = useState([]);


  const [contributionsTaxRate, setContributionsTaxRate] = useState(15 / 100);
  const [superEarningsTaxRate, setSuperEarningsTaxRate] = useState(15 / 100);
  
  const [initialRoundUpsCost, setInitialRoundUpsCost] = useState(52.80);
  //const [annualCost, setAnnualCost] = useState(initialRoundUpsCost);
  //const [cummulativeCost, setCummulativeCost] = useState(0);
  const [lifeTimeCost, setLifeTimeCost] = useState(0);

  const [additionalContributions, setAdditionalContributions] = useState(false);
  const [otherFundFees, setOtherFundFees] = useState(false);
  const [cashbackContributions, setCashbackContributions] = useState(false);
  const [changedInput, setChangedInput] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [calculatingResults, setCalculatingResults] = useState(false);
  const [graphColors, setGraphColors] = useState(['#484848', altAccentColor, '#ff5961', '#47e6b1', '#004b87']);

  const [defaultCashbackVariables, setDefaultCashbackVariables] = useState([]);

  const [isFetchingCalculatorData, setIsFetchingCalculatorData] = useState(false);
  const [isSavingCalculatorData, setIsSavingCalculatorData] = useState(false);
  const [hasFetchedCalculatorData, setHasFetchedCalculatorData] = useState(false);
  const [isFetchingDefaultCalculatorCashbackVariables, setIsFetchingDefaultCalculatorCashbackVariables] = useState(false);


  const [prevResultSuperBalanceTotalBoost, setPrevResultSuperBalanceTotalBoost] = useState(0); // For smooth transition from old to new value
  const [prevResultSuperBalanceNoBoost, setPrevResultSuperBalanceNoBoost] = useState(0); // For smooth transition from old to new value
  const [prevResultBoostedByAmountTotalBoost, setPrevResultBoostedByAmountTotalBoost] = useState(0); // For smooth transition from old to new value

  const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);
  const easeInOutCubic = (t) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  
  useEffect(() => {
    console.log('super calculator useEffect triggered with defaultCashbackVariables: ', defaultCashbackVariables);
    if (defaultCashbackVariables.length === 0){
      console.log('calculator defaultCashbackVariables is empty, fetching them now');
      fetchDefaultCalculatorCashbackVariables();
    }
  }, []);

  useEffect(() => {
    console.log("Current feesAmount:", feesAmount);
    console.log("Current feesPercentage:", feesPercentage);
    console.log('Current typeOf feesAmount:', typeof feesAmount);
  }, [feesAmount, feesPercentage]);

  useEffect(() => {
      // this useEffect is for testing only
    console.log('super calculator *** cashBackAmts changed useEffect triggered with cashbackAmts: ', cashbackAmts);
    //console.log('super calculator  cashBackAmts changed useEffect triggered with vData: ', vData);
  }, [cashbackAmts]);

  useEffect(() => {
    // this useEffect is for testing only
    console.log ('tableData useEffect triggered with tableData: ', tableData);
  }, [tableData]);

  useEffect(() => {
    // this useEffect is for testing only
    console.log('super calculator *** defaultCashbackVariables updated: ', defaultCashbackVariables);
  }, [defaultCashbackVariables]);

  useEffect(() => {
    // this useEffect is for testing only
    console.log('super calculator *** vData updated: ', vData);
  }, [vData]);

  useEffect(() => {
    // this useEffect is for testing only
    console.log('desiredRetirementAge updated to: ', desiredRetirementAge);
  }, [desiredRetirementAge]);


  useEffect(() => {
    if ( 
        !isResetInitialCashbackDefalutsDone && 
        Object.keys(vData).length > 0 &&
        Object.values(cashbackAmts).some(amount => amount > 0)
      ) {
      console.log('super calculator useEffect triggered to reset initial defaults');
      resetCashback();
      setIsResetInitialCashbackDefalutsDone(true);  // Set flag to true after running resetCashback
    }
  }, [vData]);

  useEffect(() => {
    console.log('super calculator useEffect set Amts and Frqs triggered with defaultCashbackVariables: ', defaultCashbackVariables);
    if (defaultCashbackVariables && Object.keys(defaultCashbackVariables).length > 0) {
      const newCashbackAmts = {};
      const newCashbackFrqs = {};
      const newCashbackAveAmts = {};
  
      // Iterate over each cashback variable and store values in the respective objects
      defaultCashbackVariables.forEach((item) => {
        console.log('super calculator useEffect set Amts and Frqs item is: ', item);
        const id = (item.shopping_category.replace(/,/g, '').replace(/\s/g, '')).slice(0, 5).toLowerCase();
        console.log('super calculator useEffect set Amts and Frqs set item with id: ', id);
        // Set the amount and frequency in the respective objects using the generated ID
        const freq = item.default_frequency;
        const shortFrequency = 
          freq === "Daily" ? "D" :
          freq === "Weekly" ? "W" :
          freq === "Fortnightly" ? "F" :
          freq === "Monthly" ? "M" :
          freq === "Twice/Year" ? "B" :
          freq === "Annually" ? "A" :
          freq === "Every 2 Yrs" ? "E2Y" :
          freq === "Every 3 Yrs" ? "E3Y" :
          freq === "Every 4 Yrs" ? "E4Y" : "M";
        
        newCashbackAmts[id] = Number(item.default_amount);
        newCashbackFrqs[id] = shortFrequency;
        newCashbackAveAmts[id] = item.ave_amount;
      });
  
      // Update the state with the new values
      setCashbackAmts(newCashbackAmts);
      setCashbackFrqs(newCashbackFrqs);
      setCashbackAveAmts(newCashbackAveAmts);
      console.log('calculator useEffect set Amts and Frqs updates newCashbackAmts is: ', newCashbackAmts);
      console.log('calculator useEffect set Amts and Frqs newCashbackFrqs is: ', newCashbackFrqs);
      console.log('calculator useEffect set Amts and Frqs newCasbackAveAmts is: ', newCashbackAveAmts);

      console.log('calculator useEffect set Amts and Frqs firstLoad is: ', firstLoad);
      console.log('calculator useEffect set Amts and Frqs changedInput is: ', changedInput);

    }
  }, [defaultCashbackVariables]);
  
  
  useEffect(() => {
    console.log('super calculator useEffect triggered');

    if (beforeTaxContributionAmt !== 0 && beforeTaxContributionRes === 0) {
      const beforeTaxContributionRes = calculateContribution(beforeTaxContributionAmt, beforeTaxContributionFrq);
      setBeforeTaxContributionRes(beforeTaxContributionRes);
    }

    if (roundUpsAfterTaxContributionAmt !== 0 && roundUpsAfterTaxContributionRes === 0) {
      const roundUpsAfterTaxContributionRes = calculateContribution(roundUpsAfterTaxContributionAmt, roundUpsAfterTaxContributionFrq);
      setRoundUpsAfterTaxContributionRes(roundUpsAfterTaxContributionRes);
    }

    if (personalAfterTaxContributionAmt !== 0 && personalAfterTaxContributionRes === 0) {
      const personalAfterTaxContributionRes = calculateContribution(personalAfterTaxContributionAmt, personalAfterTaxContributionFrq);
      setPersonalAfterTaxContributionRes(personalAfterTaxContributionRes);
    }

    // do we need to add other here?
  }, [ isFetchingCalculatorData, beforeTaxContributionAmt, beforeTaxContributionFrq, beforeTaxContributionRes, roundUpsAfterTaxContributionAmt, roundUpsAfterTaxContributionFrq, roundUpsAfterTaxContributionRes, personalAfterTaxContributionAmt, personalAfterTaxContributionFrq, personalAfterTaxContributionRes]);

  useEffect(() => {
      if (!!user) {
        console.log("super calculator useEffect user available let's fetchShopperCalculatorData user is: ", user);
        fetchShopperCalculatorData();
      }
  }, [ user ]);
    
  useEffect(() => {
    const beforeTaxContributionRes = calculateContribution(beforeTaxContributionAmt, beforeTaxContributionFrq);
    setBeforeTaxContributionRes(beforeTaxContributionRes);
  }, [beforeTaxContributionAmt, beforeTaxContributionFrq]);

  useEffect(() => {
    const roundUpsAfterTaxContributionRes = calculateContribution(roundUpsAfterTaxContributionAmt, roundUpsAfterTaxContributionFrq);
    setRoundUpsAfterTaxContributionRes(roundUpsAfterTaxContributionRes);
  }, [roundUpsAfterTaxContributionAmt, roundUpsAfterTaxContributionFrq]);

  useEffect(() => {
    const personalAfterTaxContributionRes = calculateContribution(personalAfterTaxContributionAmt, personalAfterTaxContributionFrq);
    setPersonalAfterTaxContributionRes(personalAfterTaxContributionRes);
  }, [personalAfterTaxContributionAmt, personalAfterTaxContributionFrq]);

  useEffect(() => {
    const otherAfterTaxContributionRes = calculateContribution(otherAfterTaxContributionAmt, otherAfterTaxContributionFrq);
    setOtherAfterTaxContributionRes(otherAfterTaxContributionRes);
  }, [otherAfterTaxContributionAmt, otherAfterTaxContributionFrq]);

  useEffect(() => {
    if (calculatingResults) {
      console.log('Calculating results...');
      calculate();
    } else if (!calculatingResults && !firstLoad) {
      console.log('First load, calculating results...');
      saveShopperCalculatorData();
    }
  }, [calculatingResults]);

  // useEffect to handle cashbackFrqs and cashbackAmts changes
  useEffect(() => {
    console.log("calculator useEffect updatedInputs cashbackAmts changed to:", cashbackAmts);
    console.log("calculator useEffect updatedInputs cashbackAveAmts is:", cashbackAveAmts);
    console.log("calculator useEffect updatedInputs cashbackFrqs changed:", cashbackFrqs);
    console.log("calculator useEffect updatedInputs defaultCashbackVariables:", defaultCashbackVariables);
    console.log("calculator useEffect updatedInputs initial *** vData: ", vData)

    if (defaultCashbackVariables && Object.keys(defaultCashbackVariables).length > 0) {

      const updatedVData = { ...vData };
      
      Object.keys(cashbackAmts).forEach((key) => {
        console.log("calculator useEffect cashbackAmts key is: ", key);
        console.log("calculator useEffect cashbackAmts value is: ", cashbackAmts[key]);
        console.log("calculator useEffect cashbackFrqs key is: ", key);
        console.log("calculator useEffect cashbackFrqs value is: ", cashbackFrqs[key]);
        console.log("calculator useEffect cashbackAveAmts key is: ", key);
        console.log("calculator useEffect cashbackAveAmts value is: ", cashbackAveAmts[key]);
        console.log("calculator useEffect defaultCashbackVariables key is: ", key);
        console.log("calculator useEffect defaultCashbackVariables value is: ", defaultCashbackVariables[key]);
        
        const amt = cashbackAmts[key];
        const avg = cashbackAveAmts[key]; 
        const freqCode = cashbackFrqs[key]; 

        //let freqCode = freq==="Daily"?"D":(freq==="Weekly"?"W":freq==="Fortnightly"?"F":freq==="Monthly"?"M":freq==="Twice/Year"?"B":freq==="Annually"?"A":freq==="Every 2 Yrs"?"E2Y":"Every 3 Yrs"?"E3Y":"Every 4 Yrs"?"E4Y":'M');

        //console.log("calculator useEffect UpdatedInputs freqCode : ", freqCode);
        console.log("calculator useEffect UpdateInputs amt : ", amt);
        console.log("calculator useEffect UpdateInputs avg: ", avg);
        console.log("calculator useEffect UpdateInputs typeof(amt): ", typeof(amt));
        console.log("calculator useEffect UpdateInputs typeof(avg): ", typeof(avg));
        console.log("calculator useEffect UpdateInputs typeof(freq): ", typeof(freq));

        const result = (
          freqCode === "D" ? amt * (avg / 100) * 365 :
          freqCode === "W" ? amt * (avg / 100) * 52 :
          freqCode === "F" ? amt * (avg / 100) * 26 :
          freqCode === "M" ? amt * avg * 12 / 100 :
          freqCode === "A" ? amt * (avg / 100) :
          freqCode === "B" ? amt * (avg / 100) * 2 :
          freqCode === "E2Y" ? amt * (avg / 100) / 2 :
          freqCode === "E3Y" ? amt * (avg / 100) / 3 :
          freqCode === "E4Y" ? amt * (avg / 100) / 4 :
          "error"
        );

        console.log("calculator useEffect UpdatedInputs result is: ", result);

        updatedVData[key] = Number(result);
      });

      console.log("calculator useEffect updatedInputs *** final vData: ", updatedVData)

      setVData(updatedVData);
      console.log("calculator useEffect updatedInputs *** changedInput is: ", changedInput);
      console.log("calculator useEffect updatedInputs *** firstLoad is: ", firstLoad);

      if (!firstLoad) {
        setChangedInput(true);
      }


    }
  }, [cashbackAmts, cashbackFrqs, defaultCashbackVariables]);


  useEffect(() => {
    console.log('******* super calculator state updated with calculators: ', calculators);
    if (age !== calculators.age) setAge(calculators.age);
    if (gender !== calculators.gender) setGender(calculators.gender);
    if (desiredRetirementAge !== calculators.desired_retirement_age) setDesiredRetirementAge(calculators.desired_retirement_age);
    if (income !== calculators.income) setIncome(calculators.income);
    if (currentSuperBalance !== calculators.current_super_balance) setCurrentSuperBalance(calculators.current_super_balance);
    if (investmentReturn !== calculators.pa_investment_return) setInvestmentReturn(calculators.pa_investment_return);
    if (inflation !== calculators.inflation) setInflation(calculators.inflation);
    if (feesAmount !== calculators.superfund_fees_pa) setFeesAmount(calculators.superfund_fees_pa);
    if (feesPercentage !== calculators.superfund_fees_pc) setFeesPercentage(calculators.superfund_fees_pc);
    if (beforeTaxContributionAmt !== calculators.before_tax_contributions) setBeforeTaxContributionAmt(calculators.before_tax_contributions);
    if (beforeTaxContributionFrq !== calculators.before_tax_contributions_frequency) setBeforeTaxContributionFrq(calculators.before_tax_contributions_frequency);
    if (roundUpsAfterTaxContributionAmt !== (calculators.after_tax_contributions)) setRoundUpsAfterTaxContributionAmt(calculators.after_tax_contributions || 40);
    //if (roundUpsAfterTaxContributionAmt !== (calculators.round_ups_after_tax_contributions || 40)) setRoundUpsAfterTaxContributionAmt(calculators.round_ups_after_tax_contributions || 40);
    if (roundUpsAfterTaxContributionFrq !== (calculators.after_tax_contributions_frequency)) setRoundUpsAfterTaxContributionFrq(calculators.after_tax_contributions_frequency || 'M');
    //roundUps is currently storing as after_tax_contributions on backend until backend is updated.
  }, [calculators]);
  


  const fetchDefaultCalculatorCashbackVariables = async () => {
    console.log('fetchDefaultCalculatorCashbackVariables triggered in super-calculator');
    setIsFetchingDefaultCalculatorCashbackVariables(true);
  
    try {
      const onFetchDefaultCalculatorCashbackVariablesResult = await dispatch(CalculatorActions.fetchDefaultCalculatorCashbackVariables());
      console.log('onFetchDefaultCalculatorCashbackVariablesResult is: ', onFetchDefaultCalculatorCashbackVariablesResult);

      setDefaultCashbackVariables(onFetchDefaultCalculatorCashbackVariablesResult.data.calculator_variables);
  
    } catch (exception) {
      console.error(exception);
      log({
        event: "System-Error",
        source: "Fetch Default Calculator Cashback Variables",
        info: exception,
        ...exception,
      });
    } finally {
      setIsFetchingDefaultCalculatorCashbackVariables(false);
      console.log('calculator defaultCashbackVariables in finally after setting them in state: ', defaultCashbackVariables);
    }
  };


  const fetchShopperCalculatorData = async () => {
    console.log('fetchShopperCalculatorData triggered');
    setIsFetchingCalculatorData(true);

    try {
      const onFetchShopperCalculatorDataResult = await dispatch(CalculatorActions.fetchShopperCalculatorData());
      console.log('onFetchShopperCalculatorDataResult is: ', onFetchShopperCalculatorDataResult);

      if (onFetchShopperCalculatorDataResult.data.data.length > 0) {
        dispatch(CalculatorActions.setShopperCalculatorData(onFetchShopperCalculatorDataResult.data.data[0]));
        setHasFetchedCalculatorData(true);
      }

    } catch (exception) {
      console.error(exception);
      log({
        event: "System-Error",
        source: "Fetch Calculator Shopper Content",
        info: exception,
        ...exception,
      });
    } finally {
      setIsFetchingCalculatorData(false);
    }
  };
  
  const saveShopperCalculatorData = async () => {
    setIsSavingCalculatorData(true);
  
    const form = {
      age: age,
      gender: gender,
      desired_retirement_age: desiredRetirementAge,
      income: income,
      current_super_balance: currentSuperBalance,
      pa_investment_return: investmentReturn,
      inflation: inflation,
      superfund_fees_pa: feesAmount,
      superfund_fees_pc: feesPercentage,
      before_tax_contributions: beforeTaxContributionAmt,
      before_tax_contributions_frequency: beforeTaxContributionFrq,
      //the following 2 lines can be removed after the backend is updated to accept the new fields
      //after_tax_contributions: totalAfterTaxBoost(), // Custom function call
      //after_tax_contributions_frequency: personalAfterTaxContributionFrq,
      after_tax_contributions: roundUpsAfterTaxContributionAmt, // Custom function call
      after_tax_contributions_frequency: roundUpsAfterTaxContributionFrq,

      round_ups_after_tax_contributions: roundUpsAfterTaxContributionAmt,
      round_ups_after_tax_contributions_frequency: roundUpsAfterTaxContributionFrq,
      personal_after_tax_contributions: personalAfterTaxContributionAmt,
      personal_after_tax_contributions_frequency: personalAfterTaxContributionFrq,
      other_after_tax_contributions: otherAfterTaxContributionAmt,
      other_after_tax_contributions_frequency: otherAfterTaxContributionFrq,
    };
  
    try {
      const onsaveShopperCalculatorDataResult = await dispatch(CalculatorActions.saveShopperCalculatorData(form));
  
      console.log('onsaveShopperCalculatorDataResult is: ', onsaveShopperCalculatorDataResult);
  
    } catch (exception) {
      console.error(exception);
      log({
        event: "System-Error",
        source: "Save Calculator Shopper Data",
        info: exception,
        ...exception,
      });
    } finally {
      console.log('Finished saving shopper calc data, updating props with the latest form:', form);
      dispatch(CalculatorActions.setShopperCalculatorData(form));
      setIsSavingCalculatorData(false);
    }
  };



  const calculateContribution = (amount, frequency) => {
    switch (frequency) {
      case 'D':
        return amount * 365;
      case 'W':
        return amount * 52;
      case 'F':
        return amount * 26;
      case 'M':
        return amount * 12;
      case 'A':
        return amount;
      case 'B':
        return amount * 2;
      case 'E2Y':
        return amount / 2;
      case 'E3Y':
        return amount / 3;
      case 'E4Y':
        return amount / 4;
      default:
        return 0;
    }
  };

  const changeInputBasic = (event) => {
      console.log('form change event is : ', event);
      console.log('event target name is: ', event.target.name);
      console.log('event target value is: ', event.target.value);

      const { name, value } = event.target;

      console.log('event value type before conversion: ', typeof value);

      const numericFields = ['age', 'desiredRetirementAge', 'income', 'currentSuperBalance', 'investmentReturn', 'inflation', 'feesAmount', 'feesPercentage', 'beforeTaxContributionAmt', 'personalAfterTaxContributionAmt', 'roundUpsAfterTaxContributionAmt', 'otherAfterTaxContributionAmt'];

      let processedValue = value;
      if (numericFields.includes(name) && value === "") {
          processedValue = 0;
      } else if (!numericFields.includes(name) && value === "") {
          // If it's a non-numeric field and cleared, ignore the change
          return;
      }  
      
      // Check if the field requires a numeric value
      if (numericFields.includes(name)) {
          // Ensure the value is a valid number
          const isValidNumber = /^\d+(\.\d+)?$/.test(processedValue);
          if (!isValidNumber && value !== '') {
              console.warn(`Invalid input for ${name}: ${value}. Only numbers are allowed.`);
              return; // Stop further processing
          }
      }
    
      setChangedInput(true);

      switch (name) {
        case 'age':
            setAge(Number(processedValue));
            break;
        case 'gender':
            setGender(processedValue);
            break;
        case 'income':
            setIncome(Number(processedValue));
            break;
        case 'currentSuperBalance':
            setCurrentSuperBalance(Number(processedValue));
            break;
        case 'investmentReturn':
            setInvestmentReturn(Number(processedValue));
            break;
        case 'inflation':
            setInflation(Number(processedValue));
            break;
        case 'feesAmount':
            setFeesAmount(Number(processedValue));
            break;
        case 'feesPercentage':
            setFeesPercentage(Number(processedValue));
            break;
        case 'beforeTaxContributionAmt':
            setBeforeTaxContributionAmt(Number(processedValue));
            break;
        case 'beforeTaxContributionFrq':
            setBeforeTaxContributionFrq(processedValue);
            break;
        case 'personalAfterTaxContributionAmt':
            setPersonalAfterTaxContributionAmt(Number(processedValue));
            break;
        case 'personalAfterTaxContributionFrq':
            setPersonalAfterTaxContributionFrq(processedValue);
            break;
        case 'roundUpsAfterTaxContributionAmt':
            setRoundUpsAfterTaxContributionAmt(Number(processedValue));
            break;
        case 'roundUpsAfterTaxContributionFrq':
            setRoundUpsAfterTaxContributionFrq(processedValue);
            break;
        case 'otherAfterTaxContributionAmt':
            setOtherAfterTaxContributionAmt(Number(processedValue));
            break;
        case 'otherAfterTaxContributionFrq':
            setOtherAfterTaxContributionFrq(processedValue);
            break;
        default:
            console.warn(`Unknown field: ${name}`);
            break;
    }
  };

  const employerContributionsPrecentageOptions = [
    { value: 0.12, label: '12%' },
    { value: 0.154, label : '15.4% (PS Member)' },
    { value: 0.164, label : '16.4% (ADF Member)' },
  ]

  const genderOptions = [
    { value: 'Not Specified', label: 'Not Specified' },
    { value: 'Female', label : 'Female' },
    { value: 'Male', label : 'Male' },
    { value: 'Non binary', label : 'Non binary' },
    { value: 'Not telling', label : 'Not telling' },
  ]

  
  const changeOtherFundFees = (event) => {
    const { id, value } = event.target;
  
    console.log(`Updating field ${id} with value:`, value);
  
    setChangedInput(true);

    switch (id) {
      case 'feesAmount':
        setFeesAmount(Number(value));
        break;
      case 'feesPercentage':
        setFeesPercentage(Number(value));
        break;
      default:
        console.warn(`Unknown field: ${id}`);
        break;
    }
  };
  
  const changeSlider = (event) => {
    const index = Number(event.target.value);  // Slider index (1 to 5)
    console.log('changeSlider Triggered with value:', index);

    setSliderIndex(index);  // Update the slider index
    setEnergyAmt(sliderValues[index - 1]);  // Update energyAmt based on slider index
  };

  const changeDesiredRetirementAgeSlider = (event) => {
    const index = Number(event.target.value);  // Slider index (60 to 70)
    console.log('changeDesiredRetirementAgeSlider Triggered with value:', index);
    setDesiredRetirementAge(index);
    setChangedInput(true);
  };
  
  const cashbackBoost = () => {
    console.log('cashbackBoost Triggered with vData:', vData);
    let total = energyAmt;  // Start with energyAmt value
  
    // Iterate over vData and sum its values
    Object.keys(vData).forEach((key) => {
      total += Number(vData[key]) || 0;
    });
  
    return total; 
  };

  
  const totalAfterTaxBoost = () => {
    console.log('totalAfterTaxBoost Triggered');

    const totalAfterTaxBoost = 
      roundUpsAfterTaxContributionRes + 
      personalAfterTaxContributionRes + 
      otherAfterTaxContributionRes;
      //note this excludes cashback which is treated separately
  
    return totalAfterTaxBoost;
  };
  

  const graph1Data = () => {
    return [
      { name: 'NoExtraContributions', value: Math.round(resultSuperBalanceNoBoost) },
    ];
  };
  
  const graph2Data = () => {
    return [
      { name: 'NoExtraContributions', value: Math.round(resultSuperBalanceNoBoost) },
      { name: 'ExtraSuperTotalContributions', value: Math.round(resultBoostedByAmountTotalBoost) },
    ];
  };
  
  const graph3Data = () => {
    return [
      { 
        name: 'Super Balance At Retirement Age', 
        valueNoBoost: Math.round(resultSuperBalanceNoBoost),
        valueBoost: Math.round(resultBoostedByAmountTotalBoost),
      },
    ];
  };


  const graphCalculatorGrowthChartData = tableData
    .filter(row => row[1] <= desiredRetirementAge)
    .map(row => {
      return {
        i: row[0],  // 'i'
        age: row[1],  // 'currentAge'
        balanceNoBoost: row[14],  // 'balanceNoBoost'
        balanceTotalBoost: row[18]  // 'balanceTotalBoost'THIIS WRONG!!
      };
  });
  
  const totalOtherContributions = () => {
    return beforeTaxContributionRes + totalAfterTaxBoost();
  };

  const updateDate = (year) => {
    const d = new Date();
    const currDate = d.getDate();
    const currMonth = d.getMonth() + 1;
    const currYear = d.getFullYear() + year;
    console.log("currDate is: ", currDate);
    console.log("currMonth is: ", currMonth);
    console.log("currYear is: ", currYear);
  
    const formattedDate = `${currYear}-${currMonth < 10 ? '0' + currMonth : currMonth}-${currDate < 10 ? '0' + currDate : currDate}`;
    console.log('updateDate return curr date is', formattedDate);
    
    return formattedDate;
  };
  
  const initiateCalculation = () => {
    console.log('initiateCalculation triggered with calculatingResults:', calculatingResults);
  
    setShowResults(true);
    setCalculatingResults(true); // this will trigger the useEffect to calculate the results
  
  };

  const resetEnergy = () => {
    console.log('resetEnergy triggered');
    
    // Use ref for the energy input element
    const energyInputRef = document.getElementById("energy-input-frequency");
    
    if (energyInputRef) {
      energyInputRef.value = 1;
      energyInputRef.dispatchEvent(new Event("change", { bubbles: true }));
    }
  
    // Reset energyAmt in the state
    setEnergyAmt(0);
    setChangedInput(true);
  };

  
  const resetCashback = () => {
    console.log('super calculator ** resetCashback triggered');
    console.log('super calculator ** vData in resetCashback is:', vData);
  
    if (Object.keys(vData).length === 0) {
      console.log("vData is empty. No action performed.");
      return;
    }

    let updatedCashbackAmts = { ...cashbackAmts }; 
  
    for (const key in vData) {
      if (vData.hasOwnProperty(key)) {
        updatedCashbackAmts[key] = 0;  
      }
    }
    
    setCashbackAmts(updatedCashbackAmts); 

    console.log('super calculator ** resetCashback cashbackAmts to be reset to:', updatedCashbackAmts);
  
    resetEnergy();
  };
  
  
  const calculate = () => {
    console.log("calculate triggered");
    console.log("income  : ", income);
    console.log("inflation : ", inflation);
    console.log("age : ", age);
    console.log("gender : ", gender);
    console.log("currentSuperBalance : ", currentSuperBalance);
    console.log("beforeTaxContributionAmt: ", beforeTaxContributionAmt);
    console.log("feesAmount : ", feesAmount);
    console.log("feesPercentage : ", feesPercentage);

    setPrevResultSuperBalanceNoBoost(resultSuperBalanceNoBoost);
    setPrevResultSuperBalanceTotalBoost(resultSuperBalanceTotalBoost);
    setPrevResultBoostedByAmountTotalBoost(resultBoostedByAmountTotalBoost);

    let table = [];
    let currentAge = age;
    let dates = ['2021-01-01T12:00','2022-01-01T12:00','2023-01-01T12:00','2024-01-01T12:00','2025-01-01T12:00'];
    let balanceNoBoostPrev = currentSuperBalance;
    let balanceBeforeTaxBoostOnlyPrev = currentSuperBalance;
    let balanceAfterTaxBoostOnlyPrev = currentSuperBalance;
    let balanceCashbackBoostOnlyPrev = currentSuperBalance;
    let balanceTotalBoostPrev = currentSuperBalance;
    let incomePrev, employerContributionsPercentagePrev, indexPrev;
    
    let contributionsEmployer = '';
    let contributionsBeforeTax = '';
    let contributionsAfterTax = '';
    let contributionsCashback = '';
    let contributionsPercentSpend = '0';

    let balanceNoBoost = currentSuperBalance;
    let balanceBeforeTaxBoostOnly = currentSuperBalance;
    let balanceAfterTaxBoostOnly = currentSuperBalance;
    let balanceCashbackBoostOnly = currentSuperBalance;
    let balanceTotalBoost = currentSuperBalance;
    
    let boostAmountBeforeTaxBoostOnly = balanceBeforeTaxBoostOnly - balanceNoBoost;
    let boostAmountAfterTaxBoostOnly = balanceAfterTaxBoostOnly - balanceNoBoost;
    let boostAmountCashbackBoostOnly = balanceCashbackBoostOnly - balanceNoBoost;
    let boostAmountTotalBoost = balanceTotalBoost - balanceNoBoost;

    let ieNoBoostBalance = ''; // the old ienb
    let ieBeforeTaxContributionsBalance = '';
    let ieAfterTaxContributionsBalance = '';
    let ieCashbackContributionsBalance = '';
    let iePercentageSpendContributionsBalance = '';
    let ieOnTotalBoostAmount = '';

    let annualCost = initialRoundUpsCost;
    let cummulativeCost = initialRoundUpsCost;


    try {
      for (let i = 0; i <= 60; i++) {
        let row = [];
        let date = updateDate(i); 
        let incomeCurrent = income * (Math.pow(1 + Number(inflation / 100), i));
        let employerContributionsPercentageCurrent = employerContributionsPercentage //calculateEmployerContributionsPercentage(date, dates);

        if (i > 0) {
          console.log("CHECK ROW Number i: ", i);
          currentAge++;

          ieNoBoostBalance = balanceNoBoostPrev * Number(investmentReturn / 100) * (1 - superEarningsTaxRate);
          ieBeforeTaxContributionsBalance = balanceBeforeTaxBoostOnlyPrev * Number(investmentReturn / 100) * (1 - superEarningsTaxRate);
          ieAfterTaxContributionsBalance = balanceAfterTaxBoostOnlyPrev * Number(investmentReturn / 100) * (1 - superEarningsTaxRate);
          ieCashbackContributionsBalance = balanceCashbackBoostOnlyPrev * Number(investmentReturn / 100) * (1 - superEarningsTaxRate);
          ieOnTotalBoostAmount = balanceTotalBoostPrev * Number(investmentReturn / 100) * (1 - superEarningsTaxRate);

          // Contributions
          contributionsEmployer = (incomePrev * employerContributionsPercentagePrev) * (1 - contributionsTaxRate);
          contributionsBeforeTax = Number(beforeTaxContributionRes) * Math.pow(1 + Number(inflation / 100), indexPrev)*Number(1-contributionsTaxRate);

          contributionsAfterTax = Number(totalAfterTaxBoost()) * Math.pow(1 + Number(inflation / 100), indexPrev);
          contributionsCashback = Number(cashbackBoost()) * Math.pow(1 + Number(inflation / 100), indexPrev);

          // Balance calculations
          balanceNoBoost = calculateBalance(balanceNoBoostPrev, ieNoBoostBalance, contributionsEmployer, feesPercentage, feesAmount, i);
          balanceBeforeTaxBoostOnly = calculateBalance(balanceBeforeTaxBoostOnlyPrev, ieBeforeTaxContributionsBalance, (contributionsBeforeTax + contributionsEmployer) , feesPercentage, feesAmount, i);
          balanceAfterTaxBoostOnly = calculateBalance(balanceAfterTaxBoostOnlyPrev, ieAfterTaxContributionsBalance, (contributionsAfterTax + contributionsEmployer), feesPercentage, feesAmount, i);
          balanceCashbackBoostOnly = calculateBalance(balanceCashbackBoostOnlyPrev, ieCashbackContributionsBalance, (contributionsCashback + contributionsEmployer), feesPercentage, feesAmount, i);

          console.log("CHECK ROW Number ********************** i: ", i, '******************************');
          console.log("CHECK CALCULATING balanceBeforeTaxBoostOnly..");
          console.log("CHECK balanceBeforeTaxBoostOnlyPrev : ", balanceBeforeTaxBoostOnlyPrev);
          console.log("CHECK contributionsBeforeTax : ", contributionsBeforeTax);
          console.log("CHECK contributionsEmployer : ",contributionsEmployer);
          console.log("CHECK ieNoBoostBalance : ", ieNoBoostBalance);
          console.log("CHECK ieBeforeTaxContributionsBalance : ", ieBeforeTaxContributionsBalance);
          console.log("CHECK ieAfterTaxContributionsBalance : ", ieAfterTaxContributionsBalance);
          console.log("CHECK ieCashbackContributionsBalance : ", ieCashbackContributionsBalance);
          console.log("CHECK ieOnTotalBoostAmount : ", ieOnTotalBoostAmount);
          console.log("CHECK balanceBeforeTaxBoostOnly : ", balanceBeforeTaxBoostOnly);
          console.log("CHECK beforeTaxContributionAmt : ", beforeTaxContributionAmt);
          console.log("CHECK incomePrev : ", incomePrev);
          console.log("CHECK incomeCurrent : ", incomeCurrent);
          console.log("CHECK employerContributionsPercentagePrev : ", employerContributionsPercentagePrev);
          console.log("CHECK contributionsTaxRate : ", contributionsTaxRate);
          console.log("CHECK feesPercentage : ", feesPercentage);
          console.log("CHECK feesAmount : ", feesAmount);
          console.log("CHECK superEarningsTaxRate : ", superEarningsTaxRate);
          console.log("CHECK investmentReturn : ", investmentReturn);

          console.log("*** balanceTotalBoostPrev : ",balanceTotalBoostPrev);
          console.log("*** contributionsBeforeTax : ",contributionsBeforeTax);
          console.log("*** contributionsAfterTax : ",contributionsAfterTax);
          console.log("*** contributionsEmployer : ",contributionsEmployer);
          console.log("*** contributionsCashback : ",contributionsCashback);
          console.log("*** ieOnTotalBoostAmount : ",ieOnTotalBoostAmount);
          console.log("*** balanceNoBoost : ",balanceNoBoost);
          console.log("*** balanceBeforeTaxBoostOnly : ",balanceBeforeTaxBoostOnly);
          console.log("*** balanceAfterTaxBoostOnly : ",balanceAfterTaxBoostOnly);
          console.log("*** balanceCashbackBoostOnly : ",balanceCashbackBoostOnly);
          console.log("*** balanceTotalBoost : ",balanceTotalBoost);
          console.log("*** boostAmountBeforeTaxBoostOnly : ",boostAmountBeforeTaxBoostOnly);
          console.log("*** boostAmountAfterTaxBoostOnly : ",boostAmountAfterTaxBoostOnly);
          console.log("*** boostAmountCashbackBoostOnly : ",boostAmountCashbackBoostOnly);
          console.log("*** balanceBeforeTaxBoostOnlyPrev : ",balanceBeforeTaxBoostOnlyPrev);
          console.log("*** balanceAfterTaxBoostOnlyPrev : ",balanceAfterTaxBoostOnlyPrev);
          console.log("*** balanceCashbackBoostOnlyPrev : ",balanceCashbackBoostOnlyPrev);


          // Total balance with all boosts
          balanceTotalBoost = calculateBalance(balanceTotalBoostPrev, ieOnTotalBoostAmount, contributionsEmployer + contributionsBeforeTax + contributionsAfterTax + contributionsCashback, feesPercentage, feesAmount, i);
          console.log("*** CHECK CALCULATING balanceTotalBoost..", balanceTotalBoost);

          // Boost amounts
          boostAmountBeforeTaxBoostOnly = balanceBeforeTaxBoostOnly - balanceNoBoost;
          boostAmountAfterTaxBoostOnly = balanceAfterTaxBoostOnly - balanceNoBoost;
          boostAmountCashbackBoostOnly = balanceCashbackBoostOnly - balanceNoBoost;
          boostAmountTotalBoost = balanceTotalBoost - balanceNoBoost;

          //Costing
          annualCost = (initialRoundUpsCost)*Math.pow(1 + Number(inflation / 100), i);
          cummulativeCost += annualCost;
          //contributionsAfterTax = Number(totalAfterTaxBoost()) * Math.pow(1 + Number(inflation / 100), indexPrev);

        }

        // Store previous balances for the next iteration
        incomePrev = incomeCurrent;
        employerContributionsPercentagePrev = employerContributionsPercentageCurrent;
        balanceNoBoostPrev = balanceNoBoost;
        balanceBeforeTaxBoostOnlyPrev = balanceBeforeTaxBoostOnly;
        balanceAfterTaxBoostOnlyPrev = balanceAfterTaxBoostOnly;
        balanceCashbackBoostOnlyPrev = balanceCashbackBoostOnly;
        balanceTotalBoostPrev = balanceTotalBoost;
        indexPrev = i;

        // Prepare the row data

        row.push
          ( i,
            currentAge,
            incomeCurrent,
            date,
            employerContributionsPercentageCurrent,
            ieNoBoostBalance,
            ieBeforeTaxContributionsBalance,
            ieAfterTaxContributionsBalance,
            ieCashbackContributionsBalance,
            //iePercentageSpendContributionsBalance,
            ieOnTotalBoostAmount,
            contributionsEmployer,
            contributionsBeforeTax,
            contributionsAfterTax,
            contributionsCashback,
            //contributionsPercentSpend,
            balanceNoBoost,
            balanceBeforeTaxBoostOnly,
            balanceAfterTaxBoostOnly,
            balanceCashbackBoostOnly,
            //balancePercentSpendContributionsOnly,
            balanceTotalBoost,
            boostAmountBeforeTaxBoostOnly,
            boostAmountAfterTaxBoostOnly,
            boostAmountCashbackBoostOnly,
            //boostAmountPercentageSpendOnly,
            boostAmountTotalBoost,
            annualCost,
            cummulativeCost
          );
          console.log('row pushed to table is', [
            ( i,
              currentAge,
              incomeCurrent,
              date,
              employerContributionsPercentageCurrent,
              ieNoBoostBalance,
              ieBeforeTaxContributionsBalance,
              ieAfterTaxContributionsBalance,
              ieCashbackContributionsBalance,
              //iePercentageSpendContributionsBalance,
              ieOnTotalBoostAmount,
              contributionsEmployer,
              contributionsBeforeTax,
              contributionsAfterTax,
              contributionsCashback,
              //contributionsPercentSpend,
              balanceNoBoost,
              balanceBeforeTaxBoostOnly,
              balanceAfterTaxBoostOnly,
              balanceCashbackBoostOnly,
              //balancePercentSpendContributionsOnly,
              balanceTotalBoost,
              boostAmountBeforeTaxBoostOnly,
              boostAmountAfterTaxBoostOnly,
              boostAmountCashbackBoostOnly,
              //boostAmountPercentageSpendOnly,
              boostAmountTotalBoost,
              annualCost,
              cummulativeCost
            )
          ]);
        table.push(row);

        if (Number(currentAge) === Number(desiredRetirementAge)) {
          // Update the final results when the desired retirement age is reached
          console.log("currentAge is now : ", currentAge);
          console.log("currentAge equal to desiredRetirementAge is now : ", desiredRetirementAge);
          console.log("currentAge balanceNoBoost is now : ", balanceNoBoost);
          console.log("currentAge balanceBeforeTaxBoostOnly is now : ", balanceBeforeTaxBoostOnly);
          console.log("currentAge balanceAfterTaxBoostOnly is now : ", balanceAfterTaxBoostOnly);
          console.log("currentAge balanceCashbackBoostOnly is now : ", balanceCashbackBoostOnly);
          console.log("currentAge balanceTotalBoost is now : ", balanceTotalBoost);
          console.log("currentAge annualCost is now : ", annualCost);
          console.log("currentAge cummulativeCost is now : ", cummulativeCost);
          setResultSuperBalanceNoBoost(balanceNoBoost);
          setResultSuperBalanceBeforeTaxBoostOnly(balanceBeforeTaxBoostOnly);
          setResultSuperBalanceAfterTaxBoostOnly(balanceAfterTaxBoostOnly);
          setResultSuperBalanceCashbackBoostOnly(balanceCashbackBoostOnly);
          setResultSuperBalanceTotalBoost(balanceTotalBoost);
          //setResultBoostedByAmountBeforeTaxBoost(boostAmountBeforeTaxBoost);
          //setResultBoostedByAmountAfterTaxBoost(boostAmountAfterTaxBoost);
          //setResultBoostedByAmountCashbackBoost(boostAmountCashbackBoost);
          setResultBoostedByAmountTotalBoost(boostAmountTotalBoost);
          setLifeTimeCost(cummulativeCost)
        }
      }
    } catch (exception) {
      log({
        event: "Calculator-Error",
        source: "Calculate function",
        info: exception,
        ...exception,
      });
    } finally {
      setCalculatingResults(false);
      setShowResults(true);
      setChangedInput(false);
      setFirstLoad(false);
      setTableData(table);
    }

    console.log("Final table:", table);
  };

  const calculateBalance = (prevBalance, investmentEarnings, contributions, feesPercentage, feesAmount, yearIndex) => {
    prevBalance = Number(prevBalance);
    investmentEarnings = Number(investmentEarnings);
    contributions = Number(contributions);
    feesPercentage = Number(feesPercentage);
    feesAmount = Number(feesAmount);
    console.log("*****calculateBalance triggered*****");
    console.log("calculateBalance triggered with prevBalance: ", prevBalance);
    console.log("calculateBalance triggered with investmentEarnings: ", investmentEarnings);
    console.log("calculateBalance triggered with contributions: ", contributions);
    console.log("calculateBalance triggered with feesPercentage: ", feesPercentage);
    console.log("calculateBalance triggered with feesAmount: ", feesAmount);
    console.log("calculateBalance triggered with yearIndex: ", yearIndex);
    
    const inflationAdjustedFees = feesAmount * Math.pow(1 + Number(inflation / 100), yearIndex);
    console.log("calculateBalance triggered with inflationAdjustedFees: ", inflationAdjustedFees);

    const balance = (prevBalance + investmentEarnings + contributions)
      - (prevBalance + investmentEarnings + contributions) * feesPercentage / 100
      - inflationAdjustedFees;
    console.log("calculateBalance RESULT **** balance: ", balance);

    return balance;
  };

 

  const addCommas = (nStr) => {
    nStr = nStr.toString(); // Ensure the input is treated as a string
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : ''; // Handle decimal points
    const rgx = /(\d+)(\d{3})/; // Regular expression to find groups of thousands
    
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1,$2'); // Insert commas for every group of thousands
    }
    
    return x1 + x2; // Return the final result with commas and decimals
  };

  const summaryGraphAmounts = [
    { name: 'BALANCE', value: resultSuperBalanceNoBoost },
    { name: 'BOOSTED', value: resultSuperBalanceTotalBoost },
  ].filter(item => item !== undefined);

  const costBenefitGraphAmounts = [
    { name: 'LIFETIME COST', value: roundUpsAfterTaxContributionAmt > 0 ? lifeTimeCost : 0},
    { name: 'LIFETIME BENEFIT', value: resultBoostedByAmountTotalBoost },
  ].filter(item => item !== undefined);

  const summaryGraphAdditionalContributions = [
    { 
      name: 'BEFORE TAX',
      beforeTax: beforeTaxContributionRes,
      cashback: 0,
      roundups: 0,
      personal: 0,
      other: 0,
      total: beforeTaxContributionRes,
    },
    {
      name: 'AFTER TAX',
      beforeTax: 0,
      cashback: cashbackBoost(),
      roundups: roundUpsAfterTaxContributionRes,
      personal: personalAfterTaxContributionRes,
      other: otherAfterTaxContributionRes,
      total: totalAfterTaxBoost() + cashbackBoost(),
    }
  ];



  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
    const x = cx > 250 ? cx + radius * Math.cos(-midAngle * (Math.PI/180)) : cx;
    const y = cx > 250 ? cy + radius * Math.sin(-midAngle * (Math.PI/180)) : cy;
  
        return (
            index > 0 ?

                    <text x={x} y={y} fill="black" fontSize="15" fontWeight="bold" textAnchor={ x == cx ? 'middle' : x > cx ? 'start' : 'end'} dominantBaseline="central">
                        {'Extra $' + addCommas(Math.round(resultBoostedByAmountTotalBoost))}
                    </text>

            : null
        );
  
  };

const renderCustomizedBarLabel = ( props ) => {
    const { x, y, width, height, value } = props;
    console.log ('renderCustomizedBarLabel props is: ', props);
    return (
        <text x={x+width / 2} y={y} fill="black" fontSize="15" fontWeight="bold" textAnchor="middle" >
            {'$' + addCommas(Math.round(value))}
        </text>
    );
}

  const openDialogWithContent = (contentName) => {
    console.log('Setting Opening dialog with contentName: ', contentName);
    setActiveContentComponent(contentName);
    setShowModalDialogContributionsFees(true);
  };  
  

  const contributionButtons = (
    <div className="modal-buttons-container">
      <div className="modal-button-container">
        <div className="modal-button-with-info">
          <ActionButton 
            text={(beforeTaxContributionAmt > 0)  ? "Adjust Before Tax" : "Add Before Tax"}
            onClick={() => openDialogWithContent('dialogAddBeforeTaxContributions')}
            large={false}
          />
            <div className="circle-content">
            <span className="pop-over-container" style={{ padding: '0px' }}>
                    <PopoverHover
                    content={
                        <div style={{
                            maxWidth: '340px', /* padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left', */
                        }}
                        >
                            <p><strong>Before Tax Contributions</strong> or concessional contributions. In general the maximum amount you can contribute without being subject to extra tax is $30,000 as of 1 Jul 2024. This is known as the concessional contributions cap and the cap includes employer contributions.</p>
                        </div>
                    }
                    header = {'Before Tax Contributions'}
                    img = {null}
                    itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', color: 'black', fontSize:'22px', }} >info</i> )
                    }
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    />
                </span>
            </div>
        </div>
      </div>

      <div className="modal-button-container">
        <div className="modal-button-with-info">
          <ActionButton 
            text={(totalAfterTaxBoost() > 0) ? "Adjust After Tax" : "Add After Tax"}
            onClick={() => openDialogWithContent('dialogAddAfterTaxContributions')}
            large={false}
          />
          <div className="circle" style={{ background: `linear-gradient( #96e996 , ${altAccentColor})`, }}>
            <div className="circle-content">
            <span className="pop-over-container" style={{ padding: '0px' }}>
                    <PopoverHover
                    content={
                        <div style={{
                            maxWidth: '340px', /* padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left', */
                        }}
                        >
                            <p><strong>SuperCalcBonusBoost5%</strong> is available by adding a Round Ups After Tax contribution before you calculate.</p>
                            { !user && <p>You will need to be logged in to earn this Bonus Boost</p> }
                            <p>After Tax contributions (also known as non-concessional contributions) are made from your take-home pay.</p>
                            <p>In general  the maximum amount you can contribute without being subject to extra tax is $120,000 pa.</p>
                        </div>
                    }
                    header = {'EARN  BONUS BOOST HERE'}
                    img = {null}
                    itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', color: 'black', fontSize:'22px', }} >info</i> )
                    }
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    />
                </span>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-button-container">
        <div className="modal-button-with-info">
          <ActionButton 
            text={ cashbackBoost() > 0 ? "Adjust Cashback After Tax" : "Add Cashback After Tax" }
            onClick={() => openDialogWithContent('dialogAddCashbackContributions')} 
            large={false}
          />
            <div className="circle-content">
            <span className="pop-over-container" style={{ padding: '0px' }}>
                    <PopoverHover
                    content={
                        <div style={{
                            maxWidth: '340px', /* padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left', */
                        }}
                        >
                            <p><strong>Cashback Contributions</strong> are paid to your super as an After Tax Contribution and contribute towards the non-concessional contributions cap.</p>
                        </div>
                    }
                    header = {'Cashback Contributions'}
                    img = {null}
                    itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', color: 'black', fontSize:'22px', }} >info</i> )
                    }
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    />
                </span>
            </div>

        </div>
      </div>

      <div className="bsc-input-label" style={{ fontSize: '18px'}}>
        Employer Contributions %
        <span className="pop-over-container">
          <PopoverHover
            content={
              <div style={{
                maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
              }}
              >
              In most cases use the standard employee contributions (12%) which will be in effect from 1 Jul 2025. If you are a member of the ADF or Public Service you should select the rate of contributions that apply to you.
              </div>
            }
            header = {null}
            img = {null}
            itemToPopover = {
              ( <i className="material-icons" style={{ margin: '0 5px 0 5px',  fontSize:'22px', color:'#000' }} >info</i> )
            }
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          />
        </span>
      </div>
      <DropDownSelect
        className="bsc-drop-down-menu"
        onChange={(value) => {
          setEmployerContributionsPercentage(value.value)
          setChangedInput(true)
        }}
        dropUp={true}
        options={employerContributionsPrecentageOptions}
        label={employerContributionsPercentage}
        value={employerContributionsPercentage}
      />

  </div>
  )

  const feesButtons = (
    <div className="modal-buttons-container">

      <div className="modal-button-container">
        <div className="modal-button-with-info">
          <ActionButton 
            text="Add Fees" 
            onClick={null} 
            large={false}
          />

            <div className="circle-content">
            <span className="pop-over-container" style={{ padding: '0px' }}>
                    <PopoverHover
                    content={
                        <div style={{
                            maxWidth: '340px', /* padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left', */
                        }}
                        >
                            <p><strong>SuperCalcBonusBoost5%</strong> is available by adding a Round Ups After Tax contribution before you calculate.</p>
                            { !user && <p>You will need to be logged in to earn this Bonus Boost</p> }
                        </div>
                    }
                    header = {'EARN  BONUS BOOST HERE'}
                    img = {null}
                    itemToPopover = {
                        ( <i className="material-icons" style={{ margin: '0 5px 0 5px', color: 'black', fontSize:'22px', }} >info</i> )
                    }
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    />
                </span>
            </div>

        </div>
      </div>
    </div>
  )

  const dialogAddBeforeTaxContributions = () => (
    <div className="bsc-section bsc-section-2">
      <div className="bsc-content-heading">Enter Before Tax contributions</div>
      <div className="bsc-section-row">
          <div className="bsc-label">Salary sacrifice<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">Salary Sacrifice</div></span></span></div>
          <div className="bsc-entry">
              <div className="bsc-input prepend-input-group">
                  <span>$</span>
                  <input 
                      type="number" 
                      id="beforeTaxContributionAmt"
                      name="beforeTaxContributionAmt"
                      onChange={changeInputBasic} 
                      value={ beforeTaxContributionAmt }
                  />
              </div>
              <div className="bsc-select">
                  <select name="beforeTaxContributionFrq" id="beforeTaxContributionFrq" value={ beforeTaxContributionFrq } onChange={changeInputBasic}>
                      <option value="D">Daily</option> 
                      <option value="W">Weekly</option> 
                      <option value="F">Fortnightly</option> 
                      <option value="M">Monthly</option> 
                      <option value="B">Twice/Year</option> 
                      <option value="A">Annually</option> 
                      <option value="E2Y">Every 2 Yrs</option> :
                      <option value="E4Y">Every 4 Yrs</option> 
                  </select>
              </div>
          </div>
          <div className="bsc-result"><span className="bsc-res">{'$' + (beforeTaxContributionRes).toFixed(2) + '  pa'}</span></div>
      </div>
    </div>
  );

  const dialogAddAfterTaxContributions = () => (
    <div className="bsc-section bsc-section-2">
      <div className="bsc-content-heading">Enter After Tax contributions</div>
      <div className="bsc-section-row">
          <div className="bsc-label"><span className="bsc-highlight" >Round-Ups*</span><span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">Round-Ups are coming soon to Boost Your Super. The average Round Ups user raises $69.71 per month.</div></span></span></div>
          <div className="bsc-entry">
              <div className="bsc-input prepend-input-group">
                  <span>$</span>
                  <input 
                      type="number"
                      id="roundUpsAfterTaxContributionAmt"
                      name="roundUpsAfterTaxContributionAmt"
                      onChange={changeInputBasic} 
                      value={ roundUpsAfterTaxContributionAmt }
                  />
              </div>
              <div className="bsc-select">
                  <select 
                      name="roundUpsAfterTaxContributionFrq"
                      id="roundUpsAfterTaxContributionFrq"
                      value={ roundUpsAfterTaxContributionFrq } 
                      onChange={changeInputBasic}>
                          <option value="D">Daily</option> 
                          <option value="W">Weekly</option> 
                          <option value="F">Fortnightly</option> 
                          <option value="M" >Monthly</option> 
                          <option value="B">Twice/Year</option> 
                          <option value="A">Annually</option> 
                          <option value="E2Y">Every 2 Yrs</option> 
                          <option value="E3Y">Every 3 Yrs</option> 
                          <option value="E4Y">Every 4 Yrs</option>
                  </select>
              </div>
          </div>
          <div className="bsc-result">
              <span className="bsc-res">{'$' + (roundUpsAfterTaxContributionRes).toFixed(2) + '  pa'}</span>
          </div>
      </div>

      <div className="bsc-section-row">
          <div className="bsc-label">Personal<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">This could include tax returns or regular personal contributions, etc</div></span></span></div>
          <div className="bsc-entry">
              <div className="bsc-input prepend-input-group">
                  <span>$</span>
                  <input 
                      type="number"
                      id="personalAfterTaxContributionAmt"
                      name="personalAfterTaxContributionAmt"
                      onChange={changeInputBasic} 
                      value={ personalAfterTaxContributionAmt }
                  />
              </div>
              <div className="bsc-select">
                  <select 
                      name="personalAfterTaxContributionFrq"
                      id="personalAfterTaxContributionFrq"
                      value={ personalAfterTaxContributionFrq } 
                      onChange={changeInputBasic}>
                          <option value="D">Daily</option> 
                          <option value="W">Weekly</option> 
                          <option value="F">Fortnightly</option> 
                          <option value="M" >Monthly</option> 
                          <option value="B">Twice/Year</option> 
                          <option value="A">Annually</option> 
                          <option value="E2Y">Every 2 Yrs</option> 
                          <option value="E3Y">Every 3 Yrs</option> 
                          <option value="E4Y">Every 4 Yrs</option>
                  </select>
              </div>
          </div>
          <div className="bsc-result">
              <span className="bsc-res">{'$' + (personalAfterTaxContributionRes).toFixed(2) + '  pa'}</span>
          </div>
      </div>

      <div className="bsc-section-row">
          <div className="bsc-label">Other<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">This could include govt co-contributions spouse contributions etc</div></span></span></div>
          <div className="bsc-entry">
              <div className="bsc-input prepend-input-group">
                  <span>$</span>
                  <input 
                      type="number"
                      id="otherAfterTaxContributionAmt"
                      name="otherAfterTaxContributionAmt"
                      onChange={changeInputBasic} 
                      value={ otherAfterTaxContributionAmt }
                  />
              </div>
              <div className="bsc-select">
                  <select 
                      name="otherAfterTaxContributionFrq"
                      id="otherAfterTaxContributionFrq"
                      value={ otherAfterTaxContributionFrq } 
                      onChange={changeInputBasic}>
                          <option value="D">Daily</option> 
                          <option value="W">Weekly</option> 
                          <option value="F">Fortnightly</option> 
                          <option value="M" >Monthly</option> 
                          <option value="B">Twice/Year</option> 
                          <option value="A">Annually</option> 
                          <option value="E2Y">Every 2 Yrs</option> 
                          <option value="E3Y">Every 3 Yrs</option> 
                          <option value="E4Y">Every 4 Yrs</option>
                  </select>
              </div>
          </div>
          <div className="bsc-result">
              <span className="bsc-res">{'$' + (otherAfterTaxContributionRes).toFixed(2) + '  pa'}</span>
          </div>
      </div>
      

      <div className="bsc-section-row">
          <div className="bsc-label">Shopping Cashback<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">This could include govt co-contributions spouse contributions etc</div></span></span></div>
          <div className="bsc-entry">
            <div className="bsc-shopping-cashback-info">
              Adjust this amount with the cashback button:
            </div>
          </div>
          <div className="bsc-result">
              <span className="bsc-res">{'$'+addCommas((cashbackBoost()).toFixed(2)) + ' pa'}</span>
          </div>
      </div>

      <div className="bsc-section-row">
          <div className="bsc-label bsc-center-res-label">Total After Tax contributions:</div>
          <div className="bsc-result bsc-center-res-text">{'$'+addCommas((totalAfterTaxBoost() + cashbackBoost()).toFixed(2))+'pa'}</div>
      </div>

    </div>
  );

  const dialogAddCashbackContributions = () => (
    <div className="bsc-section bsc-section-1">
      <div className="bsc-content-heading">Enter Your Online Shopping Spend
      
      </div>
      <div className="bsc-buttons-container">
          <div className="bsc-button">
            <ActionButton text="Load some defaults" onClick={fetchDefaultCalculatorCashbackVariables} large={true}/>
          </div>
          <div className="bsc-button">
            <ActionButton text="Reset to zero" onClick={resetCashback} large={true}/>
          </div>
      </div>
      {
      
      Object.keys(defaultCashbackVariables).map((key,index)=>{
          let v = defaultCashbackVariables[key];
          let keyId = (v.shopping_category.replace(/,/g,'').replace(/\s/g, '')).slice(0,5).toLowerCase();
          
          return (
          <div className="bsc-section-row" key={index}>
              <div className="bsc-label">{v.shopping_category} <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">{v.tooltip}</div></span></span></div>
              <div className="bsc-entry">
                  <div className="bsc-input prepend-input-group">
                      <span>$</span>
                      <input 
                        type="number"
                        id={keyId+'Amt'}
                        onChange={(e) => {
                          setCashbackAmts({ ...cashbackAmts, [keyId]: Number(e.target.value) });
                        }}
                        value={cashbackAmts[keyId] || 0}
                        data-avg={v.ave_amount}
                        className="calc-input"
                      />
                  </div>
                  <div className="bsc-select">
                      <select
                        name=""
                        id={keyId+'Frq'}
                        value={cashbackFrqs[keyId]}
                        onChange={(e) => {
                          setCashbackFrqs({ ...cashbackFrqs, [keyId]: e.target.value });
                        }}
                        data-avg={v.ave_amount}
                        className="calc-input"
                        >
                          <option value="D">Daily</option> 
                          <option value="W">Weekly</option> 
                          <option value="F">Fortnightly</option> 
                          <option value="M" >Monthly</option> 
                          <option value="B">Twice/Year</option> 
                          <option value="A">Annually</option> 
                          <option value="E2Y">Every 2 Yrs</option> 
                          <option value="E3Y">Every 3 Yrs</option> 
                          <option value="E4Y">Every 4 Yrs</option>
                      </select>
                  </div>
              </div>
              <div className="bsc-result"><span className="bsc-res">{ '$'+(Number(vData[keyId])).toFixed(2) }</span></div>
          </div>)
      })

      }
          
      <div className="bsc-section-row">
          <div className="bsc-label">How often do you switch energy ?<span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">How often do you switch energy</div></span></span></div>
          <div className="bsc-entry">
              <div className="bsc-slider" >
                  <input
                    type="range"
                    name=""
                    step="1"
                    min="1"
                    max="5"
                    id="energy-input-frequency"
                    onChange={changeSlider}
                    value={sliderIndex}
                    className=""
                  />
                  <div className="tickMarks">
                      <span >Never</span>
                      <span >Every 4 Yrs</span>
                      <span >Every 3 Yrs</span>
                      <span >Every 2 Yrs</span>
                      <span >Annually</span>
                  </div>
              </div>
              
          </div>
          <div className="bsc-result"><span className="bsc-res">{ '$'+energyAmt }</span></div>
      </div>
  
      <div className="bsc-section-row">
          <div className="bsc-label bsc-center-res-label">Shopping Cashback Boost per Annum:</div>
          <div className="bsc-result bsc-center-res-text">{'$'+addCommas((cashbackBoost()).toFixed(2))}</div>
      </div>
    </div>
  );

  return (
    <Formsy>
    <CalculatorWrapper resultBoostedByAmountTotalBoost={resultBoostedByAmountTotalBoost}>
      <div className="bsc-wrapper">
          <div className="bsc-container">
              <h2 className="bsc-heading">Boost Your Super Calculator</h2>
              <h3 className="bsc-sub-heading">See how different contributions can affect super</h3>
              <div className="bsc-info-text">This is a model not a prediction. It is designed  to show the effect  of using Boost Your Super and is based  on assumption and estimation. Please see the disclaimers assumptions and limitations below.</div>

              <div className="bsc-section">
                  <div className="bsc-initial-inputs-container">
                  <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: "100%" }}>
                  <Row style={{ margin: 0, padding: 0, width: '100%', justifyContent: 'center' }}>
                      <Col xl={4} lg={4} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>

                        <MyInput
                          name="age"
                          id="age"
                          type="tel"
                          validations={{ 
                            isExisty: true,
                            //maxLength: 3,
                            minValue: (values, value) => value !== '' && value >= 12,  // Ensure value is at least 0
                            maxValue: (values, value) => value !== '' && value <= 75, 
                            matchRegexp: /^\d+$/
                          }}
                          validationErrors={{
                            isExisty: 'this field is required',
                            maxValue: 'That seems too high!',
                            minValue: 'That seems a bit low!',
                            matchRegexp: 'Only numbers allowed'
                          }}
                          required
                          value={age}
                          onChange={changeInputBasic}
                          //placeholder="First Name"
                          label={
                            <div className='bsc-input-label-with-info'>
                              Age {/* <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">You can use the following as a guide: Cash = 2.7%, Conservative=3.8%, Moderate = 4.4%, Balanced = 4.8%, Growth = 5%, High Growth = 5.3%</div></span></span></div> */}
                              <span className="pop-over-container">
                              <PopoverHover
                                content={
                                  <div style={{
                                    maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                  }}
                                  >
                                  Min 16, max 75
                                  </div>
                                }
                                header = {null}
                                img = {null}
                                itemToPopover = {
                                  ( <i className="material-icons" style={{ margin: '0 5px 0 5px', fontSize:'24px', color:'#000'}} >info</i> )
                                }
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'right',
                                }}
                              />
                            </span>
                          </div>
                          }
                        />
                          <div className="bsc-input-label">
                            Gender
                            <span className="pop-over-container">
                              <PopoverHover
                                content={
                                  <div style={{
                                    maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                  }}
                                  >
                                  Coming soon. Gender will be used to show how results compare with different demographics.
                                  </div>
                                }
                                header = {null}
                                img = {null}
                                itemToPopover = {
                                  ( <i className="material-icons" style={{ margin: '0 5px 0 5px',  fontSize:'24px', color:'#000' }} >info</i> )
                                }
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'right',
                                }}
                              />
                            </span>
                          </div>
                          <DropDownSelect
                            className="bsc-drop-down-menu"
                            onChange={(value) => {
                              setGender(value.value)
                              //setChangedInput(true); (uncomment when it has an effect on calculator output)
                            }}
                            dropUp={false}
                            options={genderOptions}
                            label={gender}
                            value={gender}
                          />
                        

                        <MyInput
                          name="income"
                          id="income"
                          type="tel"
                          validations={{ 
                            isExisty: true,
                            //maxLength: 3,
                            minValue: (values, value) => value !== '' && value >= 10,  // Ensure value is at least 0
                            maxValue: (values, value) => value !== '' && value <= 250000, 
                            matchRegexp: /^\d+$/
                          }}
                          validationErrors={{
                            isExisty: 'this field is required',
                            maxValue: 'Please see +$250k pre-tax salary limitations',
                            minValue: 'That seems a bit low!',
                            matchRegexp: 'Only numbers allowed'
                          }}
                          required
                          value={income}
                          onChange={changeInputBasic}
                          //placeholder="First Name"
                          label={
                            <div className='bsc-input-label-with-info'>
                              Income $ Pa
                              <span className="pop-over-container">
                              <PopoverHover
                                content={
                                  <div style={{
                                    maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                  }}
                                  >
                                  $p.a. before tax and super
                                  </div>
                                }
                                header = {null}
                                img = {null}
                                itemToPopover = {
                                  ( <i className="material-icons" style={{ margin: '0 5px 0 5px', fontSize:'24px', color:'#000'}} >info</i> )
                                }
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'right',
                                }}
                              />
                            </span>
                          </div>
                          }
                        />
                        <MyInput
                          name="currentSuperBalance"
                          id="super"
                          type="tel"
                          validations={{ 
                            isExisty: true,
                            //maxLength: 3,
                            minValue: (values, value) => value !== '' && value >= 10,  // Ensure value is at least 0
                            //maxValue: (values, value) => value !== '' && value <= 250000, 
                            matchRegexp: /^\d+$/
                          }}
                          validationErrors={{
                            isExisty: 'this field is required',
                            //maxValue: 'Please see +$250k pre-tax salary limitations',
                            minValue: 'That seems a bit low!',
                            matchRegexp: 'Only numbers allowed'
                          }}
                          required
                          value={currentSuperBalance}
                          onChange={changeInputBasic}
                          //placeholder="First Name"
                          label={
                            <div className='bsc-input-label-with-info'>
                              Current Super Balance $
                              <span className="pop-over-container">
                              <PopoverHover
                                content={
                                  <div style={{
                                    maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                  }}
                                  >
                                  This is used to calculate the compounding effect it has on additional contributions from Boost Your Super. According to ABS the 2017-2018 mean balances for 55-64 age range were $332,700 for males and $183,000 for females.
                                  </div>
                                }
                                header = {null}
                                img = {null}
                                itemToPopover = {
                                  ( <i className="material-icons" style={{ margin: '0 5px 0 5px', fontSize:'24px', color:'#000'}} >info</i> )
                                }
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'right',
                                }}
                              />
                            </span>
                          </div>
                          }
                        />
                      </Col>
                      </Row>
                      </Container>
                  </div>

                  <div className="bsc-section bsc-section-3">


              {/* ***************End Basic inputs************************* */}

              {/* --------------------Start Calculate Button -------------- */}
              <div className="calculate-button-container">
                <ActionButton text="Calculate" onClick={initiateCalculation} large={true}/>
              </div>
              <div className="calc-btn-info-text">
                {firstLoad ? 'Click for result'
                  : changedInput ? 'Changed input. Click to update.'
                  : ' '}
              </div>

              {/* ********************End Calculate Button ********************* */}


    {/* ******************************START NEW RESULTS ************************************************* */}
    <div className="results-container">
      <div className="cards-container">
            <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: "100%" }}>
              <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      <DashboardCard
                        title="Boost Effect"
                        howItWorks={
                          'Use the Add Contributions card to add or adjust contributions and see the effect on super balance.'
                        }
                        buttonText={null}
                        buttonLink="/transactions"
                        buttonDisabled={true}
                        openInNewWin={false}
                        highlight={false}
                        valuePropText={
                          firstLoad ? 'Click Calculate for result' 
                          : changedInput ? "Changed input. Click Calculate to update"
                          : resultBoostedByAmountTotalBoost == 0 ? 'Enter a Contribution to see a boosted balance'
                          : null
                        }
                        valuePropTextBackgroundColor="red"
                        cardImageContent={
                          <div className="bsc-graph-stats-summary">
                            <div className="bsc-graph-result-label-container">
                              <div className="bsc-graph-result-label">
                                  Balance no boost
                              </div>
                              <div className="bsc-graph-result-text-amount">
                                <CountUp
                                  start={prevResultSuperBalanceNoBoost}
                                  end={resultSuperBalanceNoBoost}
                                  duration={3}
                                  separator=","
                                  prefix="$"
                                  easingFn={easeOutCubic} 
                                />
                              </div>
                            </div>
                            <div className="bsc-graph-result-label-container">
                              <div className="bsc-graph-result-label">
                                  Balance boosted
                              </div>
                              <div className="bsc-graph-result-text-amount">
                                <CountUp
                                  start={prevResultSuperBalanceTotalBoost}
                                  end={resultSuperBalanceTotalBoost}
                                  duration={4}
                                  separator=","
                                  prefix="$"
                                  easingFn={easeInOutCubic} 
                                />
                              </div>
                            </div>
                            <div className="bsc-graph-result-label-container">
                              <div className="bsc-graph-result-label">
                                  Extra Super
                              </div>
                              <div className="bsc-graph-result-text-amount-boost">
                                <CountUp
                                  start={prevResultBoostedByAmountTotalBoost}
                                  end={resultBoostedByAmountTotalBoost}
                                  duration={5}
                                  separator=","
                                  prefix="$"
                                  easingFn={easeOutCubic} 
                                />
                              </div>
                            </div>
                          </div>
                        }
                        icon={null}
                        onClick={() => navigate('/transactions')}
                      />
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      <DashboardCard
                        title="Adjust Contributions"
                        howItWorks={
                          contributionButtons
                        }
                        buttonText={null}
                        buttonLink={null}
                        buttonDisabled={true}
                        openInNewWin={false}
                        highlight='Test Message'
                        valuePropText={null} //{'Extra $' + addCommas(Math.round(resultBoostedByAmountTotalBoost))}
                        cardImageContent={
                          <GraphStackedBar
                            data={summaryGraphAdditionalContributions}
                          />
                        }
                        icon={null}
                        onClick={() => null}
                      />
                    </Col>
              </Row>
              <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                  <DashboardCard
                    title="Round-Ups Lifetime Cost Benefit"
                    howItWorks="The projected lifetime cost is based on $4.00 per month (+ GST) fees for Round-Ups.  Inflation is included. The lifetime benefit is the extra super as a result of investing spare change into super."
                    buttonText={!user ? "Sign Up Free" : "Goto Round-Ups"}
                    buttonLink={!user ? "/signup" : "/settings?tab=round-ups"}
                    buttonDisabled={false}
                    openInNewWin={false}
                    highlight={null } //Number(roundUpsAfterTaxContributionAmt > 0) ? null : 'Enter a Round-Ups Contribution to see a result'}
                    valuePropText={
                      firstLoad ? 'Click Calculate for result' 
                      : Number(roundUpsAfterTaxContributionAmt == 0) ? 'Enter a AFTER TAX Round-Ups Contribution'
                      : changedInput ? "Changed input. Click Calculate to update"
                      : null
                    }
                    valuePropTextBackgroundColor="red"
                    cardImageContent={
                      <GraphTotalBoostAmounts 
                        data={costBenefitGraphAmounts}
                        header={ null
                          //(roundUpsAfterTaxContributionAmt > 0) ? <h3>Enter a Round-Ups Contribution to see a result</h3> : null
                          //lifeTimeCost ? `Benefit per $1 spent: ${addCommas(Math.round(resultBoostedByAmountTotalBoost / lifeTimeCost))}` : null
                        }
                      />
                    }
                    icon={null}
                    onClick={!user ? () => navigate('/signup') : () => navigate('/settings?tab=round-ups')}
                  />
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                  <DashboardCard
                    title="More Settings"
                    howItWorks={
                      'You can adjust fees, inflation, investment return and employer contributions'
                    }
                    buttonText={null}
                    buttonLink={null}
                    buttonDisabled={true}
                    openInNewWin={false}
                    highlight={false}
                    valuePropText={null}
                    valuePropTextBackgroundColor="red"
                    cardImageContent={
                      <div className="bsc-new-inputs-container">

                          {/* <div className="bsc-input-label">
                            Employer Contributions %
                            <span className="pop-over-container">
                              <PopoverHover
                                content={
                                  <div style={{
                                    maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                  }}
                                  >
                                  In most cases use the standard employee contributions (12%) which will be in effect from 1 Jul 2025. If you are a member of the ADF or Public Service you should select the rate of contributions that apply to you.
                                  </div>
                                }
                                header = {null}
                                img = {null}
                                itemToPopover = {
                                  ( <i className="material-icons" style={{ margin: '0 5px 0 5px',  fontSize:'24px', color:'#000' }} >info</i> )
                                }
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'right',
                                }}
                              />
                            </span>
                          </div>
                          <DropDownSelect
                            className="bsc-drop-down-menu"
                            onChange={(value) => {
                              setEmployerContributionsPercentage(value.value)
                              setChangedInput(true)
                            }}
                            dropUp={false}
                            options={employerContributionsPrecentageOptions}
                            label={employerContributionsPercentage}
                            value={employerContributionsPercentage}
                          /> */}
                         
                          <MyInput
                            name="investmentReturn"
                            id="investmentReturn"
                            type="tel"
                            validations={{ 
                              isExisty: true,
                              //maxLength: 3,
                              //minValue: (values, value) => value !== '' && value >= 0,  // Ensure value is at least 0
                              maxValue: (values, value) => value !== '' && value <= 100, 
                              matchRegexp: /^\d+(\.\d+)?$/
                            }}
                            validationErrors={{
                              isExisty: 'this field is required',
                              maxValue: 'That seems way too high!',
                              matchRegexp: 'Only numbers allowed'
                            }}
                            required
                            value={investmentReturn}
                            onChange={changeInputBasic}
                            //placeholder="First Name"
                            label={
                              <div className='bsc-input-label-with-info'>
                                Investment Return % Pa {/* <span className="tooltip-container"><div className="tooltip-icon">i</div><span className="tooltip"><div className="content">You can use the following as a guide: Cash = 2.7%, Conservative=3.8%, Moderate = 4.4%, Balanced = 4.8%, Growth = 5%, High Growth = 5.3%</div></span></span></div> */}
                                <span className="pop-over-container">
                                <PopoverHover
                                  content={
                                    <div style={{
                                      maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                    }}
                                    >
                                    You can use the following as a guide: Cash = 2.7%, Conservative=3.8%, Moderate = 4.4%, Balanced = 4.8%, Growth = 5%, High Growth = 5.3%
                                    </div>
                                  }
                                  header = {null}
                                  img = {null}
                                  itemToPopover = {
                                    ( <i className="material-icons" style={{ margin: '0 5px 0 5px', fontSize:'24px', color:'#000'}} >info</i> )
                                  }
                                  anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                  }}
                                />
                              </span>
                            </div>
                            }
                          />
                          <MyInput
                            name="inflation"
                            id="inflation"
                            type="tel"
                            //validations={{ isExisty: true, maxLength: 2}}
                            //validationErrors={{ isExisty: 'this field is required', maxLength: 'this aint Argentina or Zimbabwe bruh!' }}
                            validations={{ 
                              isExisty: true,
                              //maxLength: 3,
                              //minValue: (values, value) => value !== '' && value >= 0,  // Ensure value is at least 0
                              maxValue: (values, value) => value !== '' && value <= 20, 
                              matchRegexp: /^\d+(\.\d+)?$/
                            }}
                            validationErrors={{
                              isExisty: 'this field is required',
                              maxValue: 'This aint Argentina or Zimbabwe bruh!',
                              matchRegexp: 'Only numbers allowed'
                            }}
                            required
                            value={inflation}
                            onChange={changeInputBasic}
                            //placeholder="First Name"
                            label={
                              <div className='bsc-input-label-with-info'>
                                Inflation % Pa
                                <span className="pop-over-container">
                                  <PopoverHover
                                    content={
                                      <div style={{
                                        maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                      }}
                                      >
                                      According to ABS the average inflation rate for 25 years 1991-2018 is 2.5%.
                                      </div>
                                    }
                                    header = {null}
                                    img = {null}
                                    itemToPopover = {
                                      ( <i className="material-icons" style={{ margin: '0 5px 0 5px',  fontSize:'24px', color:'#000' }} >info</i> )
                                    }
                                    anchorOrigin={{
                                      vertical: 'center',
                                      horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                      vertical: 'center',
                                      horizontal: 'right',
                                    }}
                                  />
                                </span>
                              </div>
                            }
                          />

                          <div className="bsc-fees-container">
                            <div className="bsc-half-card-container">
                              <MyInput
                                name="feesAmount"
                                id="feesAmount"
                                type="tel"
                                //validations={{ isExisty: true, maxLength: 2}}
                                //validationErrors={{ isExisty: 'this field is required', maxLength: 'this aint Argentina or Zimbabwe bruh!' }}
                                validations={{ 
                                  isExisty: true,
                                  //maxLength: 3,
                                  //minValue: (values, value) => value !== '' && value >= 0,  // Ensure value is at least 0
                                  //maxValue: (values, value) => value !== '' && value <= 20, 
                                  matchRegexp: /^(\d+(\.\d+)?|0)$/ 
                                }}
                                validationErrors={{
                                  isExisty: 'this field is required',
                                  maxValue: 'This aint Argentina or Zimbabwe bruh!',
                                  matchRegexp: 'Only numbers allowed'
                                }}
                                required
                                value={String(feesAmount)}
                                onChange={changeInputBasic}
                                //placeholder="First Name"
                                label={
                                  <div className='bsc-input-label-with-info'>
                                    Fees $ Pa
                                    <span className="pop-over-container">
                                      <PopoverHover
                                        content={
                                          <div style={{
                                            maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                          }}
                                          >
                                          You can include any PA fees that your super fund charges you
                                          </div>
                                        }
                                        header = {null}
                                        img = {null}
                                        itemToPopover = {
                                          ( <i className="material-icons" style={{ margin: '0 5px 0 5px',  fontSize:'24px', color:'#000' }} >info</i> )
                                        }
                                        anchorOrigin={{
                                          vertical: 'center',
                                          horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                          vertical: 'center',
                                          horizontal: 'right',
                                        }}
                                      />
                                    </span>
                                  </div>
                                }
                              />
                            </div>
                            <div className="bsc-half-card-container">
                              <MyInput
                                name="feesPercentage"
                                id="feesPercentage"
                                type="tel"
                                //validations={{ isExisty: true, maxLength: 2}}
                                //validationErrors={{ isExisty: 'this field is required', maxLength: 'this aint Argentina or Zimbabwe bruh!' }}
                                validations={{ 
                                  isExisty: true,
                                  //maxLength: 3,
                                  //minValue: (values, value) => value !== '' && value >= 0,  // Ensure value is at least 0
                                  //maxValue: (values, value) => value !== '' && value <= 20, 
                                  matchRegexp: /^\d+(\.\d+)?$/
                                }}
                                validationErrors={{
                                  isExisty: 'this field is required',
                                  maxValue: 'This aint Argentina or Zimbabwe bruh!',
                                  matchRegexp: 'Only numbers allowed'
                                }}
                                required
                                value={String(feesPercentage)}
                                onChange={changeInputBasic}
                                //placeholder="First Name"
                                label={
                                  <div className='bsc-input-label-with-info'>
                                    Fees % Pa
                                    <span className="pop-over-container">
                                      <PopoverHover
                                        content={
                                          <div style={{
                                            maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                          }}
                                          >
                                          You can include any percentage fees that your super fund charges you
                                          </div>
                                        }
                                        header = {null}
                                        img = {null}
                                        itemToPopover = {
                                          ( <i className="material-icons" style={{ margin: '0 5px 0 5px',  fontSize:'24px', color:'#000' }} >info</i> )
                                        }
                                        anchorOrigin={{
                                          vertical: 'center',
                                          horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                          vertical: 'center',
                                          horizontal: 'right',
                                        }}
                                      />
                                    </span>
                                  </div>
                                }
                              />
                            </div>
                          </div>

                           <div className="bsc-input-label">
                              Desired Retirement Age
                              <span className="pop-over-container">
                                <PopoverHover
                                  content={
                                    <div style={{
                                      maxWidth: '300px', padding: 10, fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left',
                                    }}
                                    >
                                    Min 60, Max 75
                                    </div>
                                  }
                                  header = {null}
                                  img = {null}
                                  itemToPopover = {
                                    ( <i className="material-icons" style={{ margin: '0 5px 0 5px',  fontSize:'24px', color:'#000' }} >info</i> )
                                  }
                                  anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                  }}
                                />
                              </span>
                            </div>


                            <div className="bsc-slider" >
                                <input
                                  type="range"
                                  name=""
                                  step="1"
                                  min="60"
                                  max="75"
                                  id="desired-retirment-age-input"
                                  onChange={changeDesiredRetirementAgeSlider}
                                  value={desiredRetirementAge}
                                  className="bsc-slider-input"
                                />
                              <div className="tickMarks">
                                {Array.from({ length: 16 }, (_, i) => 60 + i).map((tick) => (
                                  <span
                                    key={tick}
                                    className={`tickMark ${desiredRetirementAge == tick ? 'active' : ''} ${!retirementAgeTickMarks.includes(tick) && desiredRetirementAge !== tick ? 'hidden' : ''}`}
                                  >
                                    {tick}
                                  </span>
                                ))}
                              </div>
                            </div>
                        
                      </div>
                    }
                    unsetCardImageHeight={true}
                    icon={null}
                    onClick={null}
                  />
                </Col>
              {/* <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      <DashboardCard
                        title="Add Contributions"
                        howItWorks={
                          contributionButtons
                        }
                        buttonText={null}
                        buttonLink={null}
                        buttonDisabled={true}
                        openInNewWin={false}
                        highlight={false}
                        valuePropText={null} //{'Extra $' + addCommas(Math.round(resultBoostedByAmountTotalBoost))}
                        cardImageContent={
                          <div className="bsc-graph-stats-summary">
                            <div className="bsc-graph-contributions-label-container">
                              <div className="bsc-graph-contributions-label">
                                  Cashback contributions
                              </div>
                              <div className="bsc-graph-contributions-text-amount"> 
                                  { calculatingResults ? 
                                      ( 
                                      <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
                                      ) : (
                                        cashbackBoost() > 0
                                        ? <span className="bsc-highlight">{'$' + addCommas((cashbackBoost()).toFixed(2)) + '  pa'}</span>
                                        : '$' + addCommas(Math.round(cashbackBoost())) + '  pa'
                                      )
                                  }
                              </div>
                            </div>
                            <div className="bsc-graph-contributions-label-container">
                              <div className="bsc-graph-contributions-label">
                                  After Tax contributions (including Round-Ups)
                              </div>
                              <div className="bsc-graph-contributions-text-amount">
                                  { calculatingResults ? 
                                      ( <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
                                      ) : (
                                        totalAfterTaxBoost() > 0
                                          ? <span className="bsc-highlight">{'$' + (totalAfterTaxBoost()).toFixed(2) + '  pa'}</span>
                                          : '$' + addCommas(Math.round(totalAfterTaxBoost())) + '  pa'
                                      )
                                  }
                              </div>
                            </div>
                            <div className="bsc-graph-contributions-label-container">
                              <div className="bsc-graph-contributions-label">
                                  Before Tax Contributions
                              </div>
                              <div className="bsc-graph-contributions-text-amount">
                                  { calculatingResults ? 
                                      ( <Spinner spinnerColor="#000000" size={20} spinnerWidth={3} />
                                      ) : (
                                      beforeTaxContributionRes > 0
                                        ? <span className="bsc-highlight">{'$' + (beforeTaxContributionRes).toFixed(2) + '  pa'}</span>
                                        : '$0 pa'
                                      )
                                  }
                              </div>
                            </div>

                          </div>
                        }
                        icon={null}
                        onClick={() => navigate('/transactions')}
                      />
                    </Col                  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      <DashboardCard
                        title="Round-Ups Lifetime Cost Benefit"
                        howItWorks="The projected lifetime cost is based on $4.40 per month. Inflation is included."
                        buttonText="Sign Up Free"
                        buttonLink="/signup"
                        buttonDisabled={false}
                        openInNewWin={false}
                        highlight={false}
                        valuePropText={null}
                        cardImageContent={
                          <GraphTotalBoostAmounts 
                            data={costBenefitGraphAmounts}
                            header={lifeTimeCost ? `Benefit per $1 spent: ${addCommas(Math.round(resultBoostedByAmountTotalBoost / lifeTimeCost))}` : null}
                          />
                        }
                        icon={null}
                        onClick={() => navigate('/signup')}
                      />
                    </Col> */}
              </Row>
              {/* <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      <DashboardCard
                        title="Cost Benefit"
                        howItWorks="We might try adding contributions to this graph."
                        buttonText="Transactions"
                        buttonLink="/transactions"
                        buttonDisabled={false}
                        openInNewWin={false}
                        highlight={false}
                        valuePropText={`Lifetime cost $: ${addCommas(Math.round(lifeTimeCost))}`}
                        cardImageContent={
                          <GraphCalculatorBalancePie 
                            innerRadius={50}
                            outerRadius={80}
                            graphData={graph2Data()}
                            boostedAmount={addCommas(Math.round(resultBoostedByAmountTotalBoost))}
                          />
                        }
                        icon={null}
                        onClick={null}
                      />
                    </Col>


                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      <DashboardCard
                        title="Super Projected Growth"
                        howItWorks="See how your super grows over time."
                        buttonText="More Contributions"
                        buttonLink="/transactions"
                        buttonDisabled={false}
                        openInNewWin={false}
                        highlight={false}
                        valuePropText={null}
                        cardImageContent={
                          <GraphCalculatorGrowthChart 
                            data={graphCalculatorGrowthChartData}
                          />
                        }
                        icon={null}
                        onClick={null}
                      />
                    </Col>
              </Row>  */}
          </Container>
        </div>
      </div>

      {/* ****************************END NEW RESULTS ******************                       */}
                  </div>
              

              
                  </div>
              
          
              <div className="bsc-section">
              <div className="bsc-disclaimer-assumption-limitation">
                  {/* <div className="bsc-disclaimer-assumption-limitation-title">Disclaimers, Assumptions & Limitations</div> */}
                  <div className="disclaimer">
                          <div className="pop-over-container">
                              <h2>Disclaimers</h2>
                              <PopoverHover
                                  content = {
                                          <div className="pop-over-content">
                                              <ul>
                                                  <li>Boost Your Super calculator is a model not a prediction.</li>
                                                  <li>Results are based on the limited information that you provided and assumptions made about the future. They are projected estimates and are in no way guaranteed.</li>
                                                  <li>This calculator cannot predict the effect of using Boost Your Super with certainty because it will depend on your personal circumstances including time out of work etc.</li>
                                                  <li>You are responsible for your own investment decisions and should obtain specific, individual advice from a licensed financial advisor to determine if Boost Your Super is right for you.</li>
                                                  <li>This calculator does not take into account any additional cost of meeting increases in living standards. This may result in the overestimation of the projected retirement amount.</li>
                                                  <li>Percentages earned for different stores and thier products vary from time to time and although we update these percentages on the listed shops regularly, they may be out of date on this calculator and cannot be relied on.</li>
                                              </ul>
                                          </div>
                                  }
                                  header = "Disclaimers"
                                  img = {null}
                                  itemToPopover = { 
                                  (<i className="material-icons" style={{ margin: '0 10px 0 10px'}}>info</i>)
                                  }
                                  horizontalPosn = "right"
                              />
                          </div>
                  </div>
                  <div className="assumptions">
                          <div className="pop-over-container">
                              <h2>Assumptions</h2>
                              <PopoverHover
                                      content = {
                                          <div>
                                              <ul>
                                                  <li>Takes into account incremental increases of employer contributions which are incrementally increasing to 12% on 1 Jul 2025.</li>
                                                  <li>Super fund investment earnings taxed at 15%.</li>
                                                  <li>Employer and other pre-tax contributions taxed at 15%.</li>
                                              </ul>
                                          </div>
                                      }
                                      header = "Assumptions"
                                      img = {null}
                                      itemToPopover = { 
                                      (<i className="material-icons" style={{ margin: '0 10px 0 10px' }} >info</i>)
                                      }
                                      horizontalPosn = "right"
                              />
                          </div>
                  </div>
                  <div className="limitations">
                      <div className="pop-over-container">
                              <h2>Limitations</h2>
                              <PopoverHover
                                  content = {
                                      <div>
                                          <ul>
                                              <li>Calculations are made based on annual contributions being made to super</li>
                                              <li>This calculator assumes income is less than $250k when calculating taxes on pre-tax salary contributions</li>
                                          </ul>
                                      </div>
                                  }
                                  header = "Limitations"
                                  img = {null}
                                  itemToPopover = { 
                                  (<i className="material-icons" style={{ margin: '0 10px 0 10px' }} >info</i>)
                                  }
                                  horizontalPosn = "right"
                              />
                      </div>

                  </div>
              </div>
              </div>

          </div>
      </div>
      <div>
        <ModalDialogContributionsFees 
          open={showModalDialogContributionsFees}
          onCloseModalDialogContributionsFees={() => setShowModalDialogContributionsFees(false)}
          //titleComponent={"Add Contributions"}
          //explainerComponent={"Enter contributions"}
          contentComponent={
            activeContentComponent === 'dialogAddBeforeTaxContributions' 
              ? dialogAddBeforeTaxContributions()
              : activeContentComponent === 'dialogAddAfterTaxContributions'
              ? dialogAddAfterTaxContributions()
              : activeContentComponent === 'dialogAddCashbackContributions'
              ? dialogAddCashbackContributions()
              : 'dialog Data Not found'
          }
        /> 
      </div>
    </CalculatorWrapper>
    </Formsy>
  )



};
export default SuperCalculator;
