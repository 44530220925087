import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import log from "Utils/logger";

import Tabs from 'SharedComponents/tabs';
import SignInForm from 'SharedComponents/sign-in-form';
import ShareStrip from 'SharedComponents/share-strip';
import ActionButton from 'SharedComponents/action-button';
import PopoverHover from 'SharedComponents/popover-hover';
import SetRoundUps from 'SharedComponents/set-roundups';
import SetContributeNow from 'SharedComponents/set-contribute-now';
import BonusBoostRecommendation from 'SharedComponents/bonus-boost-recommendation';
import ShopperBonusBoostsGraph from 'SharedComponents/bonus-boost-graph';
import ShopperBonusBoostsTable from 'SharedComponents/bonus-boost-table';

import style from './index.css'; 

import PaymentDetailsForm from './PaymentDetails';
import SelfManagedFundsPaymentDetailsForm from './SelfManagedFundsPaymentDetailsForm';


import useFetchShopperBoostProfile from 'SharedHooks/fetch-shopper-boost-profile';
//import useFetchRoundUpsShopperData from 'SharedHooks/fetch-roundups-shopper-data';
import useCheckIsExtensionInstalled from 'SharedHooks/check-extension-installed';

import useBroadcastAbly from 'SharedHooks/receive-broadcast';

const BoostSettingsWrapper = styled.div`${style}`;

const BoostSettings = () => {
  const navigate = useNavigate(); // Updated hook
  const [searchParams] = useSearchParams(); 

  const initialTab = searchParams.get("tab");
  console.log('settings - initialTab:', initialTab);

  const authentication = useSelector((state) => state.authentication);
  //const app = useSelector((state) => state.app);
  //const boostsEnabled = useSelector((state) => state.app.partnerContent.boosts_enabled);
  const boostsEnabled = useSelector((state) => state.app?.partnerContent?.boosts_enabled ?? false);


  //const roundups = useSelector((state) => state.roundups);
  //const dispatch = useDispatch();

  console.log ('*** BoostSettings page triggered');

  const { fetchShopperBoostProfile } = useFetchShopperBoostProfile();
  //const { isFetchingRoundUpsShopperDataRef, hasFetchedRoundUpsShopperDataRef, fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
  //const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
  const { checkIsExtensionInstalled } = useCheckIsExtensionInstalled();

  useBroadcastAbly({
    channelName: `private:shopper.${authentication?.user?.shopper_id}`,
  });

  const [isSmsf, setIsSmsf] = useState(() => {
    const ownedCause = authentication.collaboratingCauses?.find(
     (cause) => cause.charity_id === authentication.user.charity_id
    );
    //return ownedCause ? ownedCause.is_smsf === "1" : null;
    return ownedCause ? ownedCause.is_smsf : null;
  });

  const [activeTab, setActiveTab] = useState( initialTab || 
  (!boostsEnabled? 'payment-details' : 'bonus-boost-power'));

  console.log('*** activeTab is:', activeTab);

  useEffect (() => {
    const ownedCause = authentication.collaboratingCauses?.find(
      (cause) => cause.charity_id === authentication.user.charity_id
     );
     console.log('*** BoostSettings - useEffect triggered with ownedCause:', ownedCause);
     setIsSmsf(ownedCause ? ownedCause.is_smsf : null);
  }, [authentication]);

  useEffect(() => {

    const currentUrlTab = new URL(window.location).searchParams.get("tab");
    console.log('settings - useEffect - activeTab triggered with NEW activeTab: ', activeTab);
    console.log('settings - useEffect - activeTab triggered with OLD currentUrlTab: ', currentUrlTab);

    if (currentUrlTab !== activeTab) {
      console.log('settings - useEffect - activeTab EXECUTING to set url to activeTab: ', activeTab);
      const url = new URL(window.location);
      url.searchParams.set('tab', activeTab);
      window.history.replaceState({}, '', url);
    }


  }, [activeTab]);

  const onActiveTabChanged = (newActiveTab) => {
    console.log('*** onActiveTabChanged triggered with newActiveTab:', newActiveTab);
    setActiveTab(newActiveTab);
  };

  const whatIsBonusBoostText = (
    <div className="ext-popover-text-container"style={{ maxWidth: '800px' }}>
      <div className="text-content-container" style={{ margin: '20px'}}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',}} >
            <h2 style={{ margin: '0px 0px 5px 5px' }}><i>A Bonus Boost is a building block for your Bonus Boost Power.</i></h2>
          </div>
          <p> Each ACTIVE Bonus Boost you earn is added to your Bonus Boost Power. This increases the amount you earn on each transaction.</p>
      </div>
    </div>
  );

  const whenCanIEarnBonusBoostText = (
    <div className="ext-popover-text-container"style={{ maxWidth: '800px' }}>
      <div className="text-content-container" style={{ margin: '20px' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',}} >
            <h2 style={{ margin: '0px 0px 5px 5px' }}><i>We normanlly let you know via email & sometimes our website when there are opportunities to earn Bonus Boosts and add them to your profile. </i></h2>
          </div>
            <em style={{ margin: '0px 0px 0px 40px' }}>Keep an eye out for actions you can take to increase your Boost Power</em>
      </div>
    </div>
  );

  const maxBonusBoostPowerText = (
    <div className="ext-popover-text-container" style={{ maxWidth: '800px' }}>
      <div className="text-content-container" style={{ margin: '20px' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',}} >
            <h2 style={{ margin: '0px 0px 5px 5px' }}><i>The MAXIMUM bonus boost that can be applied to a transaction is 100%.</i></h2>
          </div>
          <p> If your Total ACTIVE Bonus Boosts (Bonus Boost Power) on your profile exceed 100% then we add your bonus boosts to each transaction in order of highest boost amount first. This maximises your earnings. Any boosts in your profile that push the Total Boost on a transaction over 100% are excluded from that transaction.</p>
      </div>
    </div>
  );

  const renderNotLoggedInContent = () => {
      return (
        <BoostSettingsWrapper>
          <div className="sign-in-form-container">
          {/* <div style={{ maxWidth: 600, padding: 10, width: 'calc(100% - 20px)', height: '100%' }}> */}
            <SignInForm
              showEmailLogin
              header={(
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                  <h1>Login</h1>
                  <span>To access your BoostSettings</span>
                </div>
              )}
              showSocialLogin
              delayOnAfterSignIn
              onAfterSignIn={() => navigate(window.location.pathname + window.location.search)}
            />
          </div>
        </BoostSettingsWrapper>
      );
  };

  if (!authentication.user) {
    return renderNotLoggedInContent();
  } else {

    return (
      <BoostSettingsWrapper>
        <ShareStrip />
        <div className="content">
          <Tabs
            onActiveTabChanged={onActiveTabChanged}
            activeTab={activeTab}
            tabs={([
              (boostsEnabled ? {
                label: 'Bonus Boosts',
                value: 'bonus-boost-power',
              } : null),
              (authentication.user.roundups_visible ? {
                label: 'Round Ups',
                value: 'round-ups',
              } : null),
              (authentication.user.contribute_now_visible ? {
                label: 'Contribute Now',
                value: 'contribute-now',
              } : null),
              {
                label: 'Super Account',
                value: 'payment-details',
              },
            ]).filter(tab => tab !== null)}            
          />
          {
            activeTab === 'contribute-now' ? (
              <div style={{ width: '100%' }}>
                <SetContributeNow />
           {/*  <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: '100%' }}>
                  <div>
                    <p>You must have RoundUps activated to use 'Contribute Now' and 'Contribute & Shop'. </p>
                    <p>More Contribute Now Info Coming Soon</p>
                  </div>
                </Container> */}
              </div>
            ) : activeTab === 'payment-details' ? (
              <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: '100%' }}>
                <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                    <div className="payment-method-designation">
                      {
                        !isSmsf ? (
                          <h3>Funds will be paid to your Superannuation account via BPAY</h3>
                        ) : (
                            <h3>Funds will be paid to your nominated Self Managed Superannuation Fund (SMSF) account</h3>
                          )
                      }
                      <div className="switcher">
                        <div>
                          {
                            !isSmsf ? (
                              <p>Switch to a Self Managed Super Fund (SMSF) account</p>
                            ) : (
                                <p>I do not use SMSF – switch me back</p>
                              )
                          }
                        </div>
                        <div>
                          <ActionButton text="SWITCH" onClick={() => setIsSmsf(!isSmsf )} />
                        </div>
                      </div>
                    </div>
                    {
                      !isSmsf ? (
                        <PaymentDetailsForm />
                      ) : (
                          <SelfManagedFundsPaymentDetailsForm />
                        )
                    }
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                    <div style={{ backgroundColor: '#fafafa', padding: 10, margin: 10 }}>
                      <p><b style={{ fontSize: 18, fontWeight: 'bolder' }}>Note. </b>It is your responsibility to ensure these details are correct. Incorrect details may result in payments being rejected as well as rejection fees. Boost Your Super will hold any funds you raise in our account until these details are provided and are accurate. If you have any questions regarding payments please contact accounts@boostyoursuper.com.au</p>
                      <p><b style={{ fontSize: 18, fontWeight: 'bolder' }}>Super Co-Contributions</b><br />We recommend you obtain financial advice to see if these payments qualify for super co-contributions for which the govt will pay an extra 50 cents for every dollar that is paid into your Superannuation account upto a limit.</p>
                      <p><b style={{ fontSize: 18, fontWeight: 'bolder' }}>Tax Benefits</b><br />Payments we make to your super potentially have Tax Benefits for making after tax super contributions. You may be entitled to these. Talk to your account or financial advisor to find out more.</p>
                      <p><b style={{ fontSize: 18, fontWeight: 'bolder' }}>First Home Super Saver Scheme</b><br />If you qualify, these payments may be used to help grow your first home deposit sooner.</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            ) : activeTab === 'bonus-boost-power' ? (
              <div className ="bonus-boosts-info-container">
               <BonusBoostRecommendation />
                <Container fluid className="links-container" style={{ margin: 0, padding: 0, width: '100%' }}>
                  <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                  {/* </Col><div style={{ width: 'calc(100% - 20px)', padding: 10 }}> */}
                      
                    </Col>
                  </Row>
                  <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      <div 
                        className="shopper-boosts-graph-and-label-container"
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <ShopperBonusBoostsGraph />
                          <h2>BONUS BOOST POWER</h2>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="links-group" style={{ margin: 0, padding: 0 }}>
                      

                      <div className="about-bonus-boosts">
                        <div className="about-bonus-boosts-header">
                          <h1>ABOUT <span className="highlight-in-header">BONUS BOOSTS</span> AND FAQs.</h1>
                        </div>
                        <div className="about-bonus-boosts-entry">
                          <h3 >Earning Bonus Boosts</h3>
                          <p>You can earn Bonus Boosts by taking <strong>actions</strong> we believe will help towards you retiring with more super (eg salary sacrificing) or helping us better understand you (eg filling in surveys).</p>
                        </div>
                        <div className="about-bonus-boosts-entry">
                          <h3>What is a Bonus Boost?</h3>
                            <div className="pop-over-container">
                              <PopoverHover
                                content = {whatIsBonusBoostText}
                                header = {null}
                                img = {null}
                                itemToPopover = { 
                                  (<i className="material-icons" style={{ margin: '0 5px 0 5px', display: 'flex' }} >info</i>)
                                }
                                horizontalPosn = "right"
                              />
                            </div>
                        </div>
                        <div className="about-bonus-boosts-entry">
                          <h3>When to earn a Bonus Boost?</h3>
                            <div className="pop-over-container">
                              <PopoverHover
                                content = {whenCanIEarnBonusBoostText}
                                header = {null}
                                img = {null}
                                itemToPopover = { 
                                  (<i className="material-icons" style={{ margin: '0 5px 0 5px', display: 'flex' }} >info</i>)
                                }
                                horizontalPosn = "right"
                              />
                            </div>
                        </div>
                        <div className="about-bonus-boosts-entry">
                          <h3>Maximum Bonus Boosts</h3>
                            <div className="pop-over-container">
                              <PopoverHover
                                content = {maxBonusBoostPowerText}
                                header = {null}
                                img = {null}
                                itemToPopover = { 
                                  (<i className="material-icons" style={{ margin: '0 5px 0 5px', display: 'flex' }} >info</i>)
                                }
                                horizontalPosn = "right"
                              />
                            </div>
                        </div>
                      </div>
                      
                    </Col>
                  </Row>
                </Container>
                <ShopperBonusBoostsTable />
              </div>
            ) : activeTab === 'round-ups' ? (
              <SetRoundUps />
            ) : null
          }
        </div>
      </BoostSettingsWrapper>
    )
  }
}

export default BoostSettings;
