export default ({ theme, active }) => `
    font-size: 20px;
    position: relative;

    button {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      outline: none;
      border: none;
      background-color: ${theme.neutralColor};
      padding: 0 10px 5px;
      color: ${theme.lightSecondaryColor};
      cursor: pointer;

      @media(max-width: 768px) {
        font-size: 12px;
        padding: 0 5px 5px;
      }

      &.active {
        border-bottom: solid 1px ${theme.darkSecondaryColor};
        color: ${theme.darkSecondaryColor};
      }

      &:hover {
        color: ${active ? `${theme.darkSecondaryColor}` : `${theme.lightSecondaryColor}`};
      }

      .label {
        white-space: nowrap;
        width: 100%;
        text: overflow: visible;
      }
    }

    .active-indicator {
      height: 3px;
      background-color: ${active ? `${theme.darkSecondaryColor}` : `transparent`};
    }
  `;
