import axios from 'axios';
import moment from 'moment';
import uuidv1 from 'uuid/v1';
import shortHash from 'short-hash';

import log from 'Utils/logger';

import CONFIG from 'Config';
import * as AuthenticationActionTypes from './constants';
import * as AppActions from "Flux/app/actions";
import roundups from '../round-ups/reducer';

export function setUser({ auth_token, user, collaborators, collaborating_causes }) {
  return (dispatch) => {
    const loginTime = moment.utc().format();

    window.localStorage.setItem('auth_token', auth_token);
    window.localStorage.setItem('user', JSON.stringify(user));
    window.localStorage.setItem('loginTime', loginTime);
    window.localStorage.setItem('collaborators', JSON.stringify(collaborators));
    window.localStorage.setItem(
      'collaboratingCauses',
      JSON.stringify(collaborating_causes),
    );

    dispatch({
      type: AuthenticationActionTypes.SET_USER,
      payload: {
        user,
        loginTime,
        collaborators,
        collaboratingCauses: collaborating_causes,
      },
    });
  };
}

export function updateRoundupsVisibility(roundups_visibility) {
  const user = JSON.parse(window.localStorage.getItem('user'));
  user.roundups_visible = roundups_visibility;
  window.localStorage.setItem('user', JSON.stringify(user));
  
  return {
    type: AuthenticationActionTypes.SET_ROUNDUPS_VISIBILITY,
    payload: roundups_visibility,
  };
}

export function updateContributeNowVisibility( contribute_now_visibility ) {
  const user = JSON.parse(window.localStorage.getItem('user'));
  user.contribute_now_visible = contribute_now_visibility;
  window.localStorage.setItem('user', JSON.stringify(user));

  return {
    type: AuthenticationActionTypes.SET_CONTRIBUTE_NOW_VISIBILITY,
    payload: contribute_now_visibility,
  };
}

export function getCsrfToken() {

  if (window.localStorage.getItem('csrf_token') == null) {
      console.log('*** getCsrfToken() empty');
      return setCsrfToken();
  }

  console.log('*** getCsrfToken() not empty ' + window.localStorage.getItem('csrf_token'));
  return window.localStorage.getItem('csrf_token');
}

export const setCsrfToken = async () => {
    let response;
    try {
        response = await axios({
            url: `${CONFIG.apiV3Url}/sessions/csrf`,
            method: 'GET'
        });
        console.log('*** setting token ' +response.data.csrf_token.toString());
        window.localStorage.setItem('csrf_token', response.data.csrf_token.toString());
    } catch (e) {
        throw new Error(e.message)
    }

    console.info('*** returning token to string ' +response.data.csrf_token.toString());
    return response.data.csrf_token.toString();
}

export function authenticate({ email, password }) {
  return (dispatch, getState) => axios({
    url: `${CONFIG.apiV3Url}/sessions/resource`,
    method: 'POST',
    data: {
      _token: getCsrfToken(),
      method: 'email',
      email,
      password,
    },
  });
}

export function authenticateViaGoogle({ email, googleId }) {
  return () => axios({
    url: `${CONFIG.apiV3Url}/sessions/resource?method=google`,
    method: 'POST',
    data: {
      _token: getCsrfToken(),
      method: 'google',
      email,
      google_id: googleId,
    },
  });
}

export function authenticateViaNewGoogle( googleUserToken ) {
  console.log('authenticateViaNewGoogle googleUserToken is: ', googleUserToken )

  return () => axios({
    url: `${CONFIG.apiV3Url}/sessions/resource?method=google`,
    method: 'POST',
    data: {
      _token: getCsrfToken(),
      method: 'google',
      access_token: googleUserToken,
    },
  });
}

export function authenticateViaFacebook({ email, id }) {
  return () => axios({
    url: `${CONFIG.apiV3Url}/sessions/resource?method=facebook`,
    method: 'POST',
    data: {
      _token: getCsrfToken(),
      method: 'facebook',
      email: email || `admin+facebook_${id}@boostyoursuper.com.au`,
      facebook_id: id,
    },
  });
}

export function resetPassword({ email }) {
  return (dispatch, getState) => axios({
    url: `${CONFIG.apiV3Url}/passwords/forgot-password`,
    method: 'POST',
    data: {
      _token: getCsrfToken(),
      'email': email
    },
  });
}

export function register({
  email, password, name, phone,
}) {
  const storedReferrerHash = localStorage.getItem('referrer');
  const form = {
    _token: getCsrfToken(),
    charity_contact_email: email,
    charity_password: password,
    charity_contact_phone: phone,
    charity_name: name,
    charity_contact_fname: name.split(' ')[0],
    charity_contact_lname: name
      .split(' ')
      .slice(1)
      .join(' '),
    charity_web_name: `${uuidv1()}-${shortHash(name)}`,
    charity_postcode: '',
    // promotion_code: '',
    // challenger_id: '',
    // charity_ra_caption: '',
    // charity_logo: props.selectedCauseType ? props.selectedCauseType.logo : null,
    // charity_ra_photo: props.selectedCauseType ? props.selectedCauseType.ra_photo : null,
    // primary_color: '#16addc',
    // accent_color: '#c34045',
    // cause_category: props.selectedCauseType ? props.selectedCategory.id : null,
    cause_category: 3,
    // cause_type: props.selectedCauseType ? props.selectedCauseType.id : null,
    cause_type: 8,
    // charity_category_id: props.selectedCauseType ? props.selectedCauseType.charity_category_ids.split(',').map((item) => parseInt(item)) : [],
    charity_country_id: 'AU',
    referring_hash: shortHash(`${name}-${uuidv1()}-${shortHash(name)}`),
    // charity_abn: '',
    referrer_hash: storedReferrerHash || null,
  };

  return (dispatch, getState) => axios({
    url: `${CONFIG.apiV3Url}/charities/resource`,
    method: 'POST',
    data: form,
  });
}

export function registerViaNewGoogle( googleUserToken ) {
  const storedReferrerHash = localStorage.getItem('referrer');
  // TODO: A log of transformations on data
  // TODO: split name into two fname and lname
  const form = {
    _token: getCsrfToken(),
    //charity_contact_email: email,
    //charity_name: name,
    //charity_contact_fname: name.split(' ')[0],
    //charity_contact_lname: name
    //  .split(' ')
    //  .slice(1)
    //  .join(' '),
    //charity_web_name: `${uuidv1()}-${shortHash(name)}`,
    charity_postcode: '',
    cause_category: 3,
    cause_type: 8,
    charity_country_id: 'AU',
    //referring_hash: shortHash(`${name}-${uuidv1()}-${shortHash(name)}`),
    referrer_hash: storedReferrerHash || null,
    //google_id: googleId,
    //google_photo_url: imageUrl,
    access_token: googleUserToken,
    method: 'google',
  };

  return async () => axios({
    url: `${CONFIG.apiV3Url}/charities/resource?method=google`,
    method: 'POST',
    data: form,
  });
}

export function registerViaFacebook({ email, name, id }) {
  const storedReferrerHash = localStorage.getItem('referrer');
  // TODO: A log of transformations on data
  // TODO: split name into two fname and lname
  const form = {
    _token: getCsrfToken(),
    charity_contact_email: email,
    charity_name: name,
    charity_contact_fname: name.split(' ')[0],
    charity_contact_lname: name
      .split(' ')
      .slice(1)
      .join(' '),
    charity_web_name: `${uuidv1()}-${shortHash(name)}`,
    charity_postcode: '',
    cause_category: 3,
    cause_type: 8,
    charity_country_id: 'AU',
    referring_hash: shortHash(`${name}-${uuidv1()}-${shortHash(name)}`),
    referrer_hash: storedReferrerHash,
    shopper_facebook: id,
  };

  return () => axios({
    url: `${CONFIG.apiV3Url}/charities/resource?method=facebook`,
    method: 'POST',
    data: form,
  });
}

export function logout() {

  window.localStorage.removeItem('csrf_token');
  window.localStorage.removeItem('auth_token');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('loginTime');
  window.localStorage.removeItem('collaborators');
  window.localStorage.removeItem('collaboratingCauses');
  console.log('logout function executed. Initiate AppAction unSetPartnerContent from auth ACTIONS');
  //AppActions.unSetPartnerContent();

  return {
    type: AuthenticationActionTypes.UNSET_USER,
  };
}

export function fetchActivityLogs() {
  return (dispatch, getState) => {
    const { authentication } = getState();

/*     const { user, collaboratingCauses } = authentication;
    const ownedCause = collaboratingCauses.find(
      (cause) => cause.charity_id === user.charity_id,
    ); */

    return axios({
      url: `${CONFIG.apiV3Url}/activitylogs/resources`,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
      },
    });
  };
}

export function fetchShopper() {
  console.log ('fetchShopper() action triggered in flux actions');
  return (dispatch, getState) => { 
    const { authentication } = getState();
    const { user } = authentication;

    console.log ('fetchShopper() action triggered in flux actions user.shopper_id: ' + user.shopper_id);  
    return axios({
      url: `${CONFIG.apiV3Url}/sessions/resource?id=${user.shopper_id}`,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
      },
    });
  }
}


export function fetchCauseDetails(charityId) {
  console.log('*** ** fetchCauseDetails: ACTION in flux function Triggered: ');
  return (dispatch, getState) => {
    const { authentication } = getState();

/*    const { user, collaboratingCauses } = authentication;
     const ownedCause = collaboratingCauses.find(
      (cause) => cause.charity_id === user.charity_id,
    ); */

    return axios({
      url: `${CONFIG.apiV3Url}/charities/resource`,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
      },
    });
  };
}

export function setActivityLogs(activityLogs) {
  return {
    type: AuthenticationActionTypes.SET_ACTIVITY_LOGS,
    payload: activityLogs,
  };
}

export function setCauseDetails(causeDetails) {
  return {
    type: AuthenticationActionTypes.SET_CAUSE_DETAILS,
    payload: causeDetails,
  };
}

// ALTER TABLE `shopper` ADD `google_id` VARCHAR(56) NULL AFTER `anonymous_to_cause`, ADD `google_photo_url` VARCHAR(256) NULL AFTER `google_id`, ADD INDEX `google_id` (`google_id`);
