import React from 'react';
import styled from 'styled-components';

import styles from './tab.css.js';

const TabWrapper = styled.div`${styles}`;

const Tab = ({ label, value, onClick, active, count }) => (
  console.log('Tab triggered with count is ', count),
  console.log('Tab triggered with active is ', active),
  console.log('Tab triggered with label is ', label),
  console.log('Tab triggered with value is ', value),
  console.log('Tab triggered with onClick is ', onClick),
  
  <TabWrapper className="tab" active={active}>
    <button onClick={() => onClick(value)}>
      <span className="label">{label}</span>&nbsp;
      {
        count !== undefined && count !== null ?  (
          //<span className="count">{parseInt(count) !== undefined ? `(${count})` : ''}</span>
          <span className="count">{`(${count})`}</span>
        ) : null
      }
    </button>
    <div className="active-indicator"></div>
  </TabWrapper>
);

export default Tab;
