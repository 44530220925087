import React, { Component, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import Clipboard from 'react-clipboard.js';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import styles from './index.css';

import CONFIG from 'Config';

import StoreBoostsList from 'SharedComponents/store-boosts-list';
import ContributeNowButton from 'SharedComponents/contribute-now-button';

const DealWrapper = styled.div`${styles}`;

const Deal = ({ deal, onShopNow, onSetSharedItem, showShareButton = true, dealWidthMobile }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const user = useSelector(state => state.authentication.user);

  console.log('Deal triggered with deal', deal);
  console.log('Deal triggered with dealWidthMobile', dealWidthMobile);

  const storeURL = `${CONFIG.baseUrl}/shops?retail_store_id=` + deal.retail_store_id;
  const decodedStoreURL = decodeURIComponent(storeURL);

  const endDateObject = new Date(deal.end_date);
  let visibleExpiry;
  let expiry;

  if (endDateObject.toString() !== 'Invalid Date') {
    if (moment(deal.end_date).diff(moment(), 'hours') < 48) {
      if (moment(deal.end_date).diff(moment(), 'minutes') < 60) {
        visibleExpiry = moment(deal.end_date).fromNow(true);
      } else {
        visibleExpiry = moment(deal.end_date).diff(moment(), 'hours') + ' hours';
      }
    } else {
      visibleExpiry = moment(deal.end_date).fromNow(true);
    }
  }
  return (
    <DealWrapper deal={deal} dealWidthMobile={dealWidthMobile}>

      <div className="coupon-container">
        <div className="code-container">
          {
            deal.clipboardCode && deal.clipboardCode.length ? (
              <Clipboard
                className="deal-code-container"
                data-clipboard-text={deal.code}
                onSuccess={() => {
                  setCopiedToClipboard(true);
                  setTimeout(() => {
                    setCopiedToClipboard(false);
                  }, 3000);
                }}
              >
                <small className={classNames('click-to-copy', { active: copiedToClipboard })}>{!copiedToClipboard ? 'Click to copy to clipboard' : 'Copied to clipboard'}</small>
                <div className="code-container">
                  <strong className="code">{deal.code}</strong>
                </div>
              </Clipboard>
            ) : (
                <div className="no-code-required-container">
                  <h3 className="promotion-type-title">{deal.promotionTypeTitle}</h3>
                </div>
              )
          }
        </div>
        <div className="boost-amount">
          <StoreBoostsList
            retailStore={deal}
            retailStoreId={deal.retail_store_id}
          />
        </div>
      </div>
      <div className="deal-banner-and-logo-image-container">
        {deal.coupon_banner_image_url && deal.coupon_banner_image_url.length ? (
          <div className="deal-banner-image-container">
            <img className="deal-banner-image" src={deal.coupon_banner_image_url} alt={deal.merchant_name} />
            <button className="store-image-container" onClick={() => onShopNow(deal, 'deal')}>
              <img className="store-image" src={deal.retail_store_logo_url} alt={deal.merchant_name} />
              <small className="store-name">{deal.merchant_name}</small>
            </button>
          </div>
        ) : (
            <div className="deal-banner-image-container">
              <button className="store-image-container" onClick={() => onShopNow(deal, 'deal')}>
                <img className="store-image" src={deal.retail_store_logo_url} alt={deal.merchant_name} />
                <small className="store-name">{deal.merchant_name}</small>
              </button>
            </div>
          )}
      </div>

      <div className="deal-info">
        <h2 className="promotion-type">{deal.promotion_type}</h2>
        <p className="description">{deal.description}</p>
        <div className="expiry">
          {endDateObject.toString() === 'Invalid Date' ? (
            <span className="times">{`${moment(deal.start_date).fromNow(true)} old`}<span className="coupon-expiry">NO EXPIRY</span></span>
          ) : (
              <span className="times">{`${moment(deal.start_date).fromNow(true)} old`}<span className="coupon-expiry" style={{ marginLeft: 15, color: '#16addc !important' }}></span><i className="material-icons">timer</i>&nbsp;<span>{visibleExpiry}</span></span>
            )}
        </div>
      </div>
      
      <div className="actions-container">
        <button className="shop-now" onClick={() => onShopNow(deal, 'deal')}>SHOP NOW</button>
        {user?.roundups_visible ?
            <ContributeNowButton
            largeButton={ isMobile ? false : true }
            contributeAndShop={true}
            onShopNow={onShopNow}
            onShopNowType={'deal'}
            storeDetails={deal}
          />
          : null}
          
        {/*         {showShareButton ? (
          <button className="share-this-deal" onClick={() => onSetSharedItem(deal, 'deal')}>
            <i className="material-icons">share</i>&nbsp;
            <span>Share this deal</span>
          </button>
        ) : null} */}
      </div>

      <div className="deal-footer" style={{ display: 'none' }}>
        <div className="mc-offer-container">
            {deal.coupon_banner_image_url && deal.coupon_banner_image_url.length ? (
              <div
                className="deal-banner-container"
                style={{
                  width: '100%',
                  maxWidth: '600px',
                }}
              >
                <a href={decodedStoreURL}>
                  <img
                    className="deal-banner-image"
                    src={deal.coupon_banner_image_url}
                    alt={deal.merchant_name}
                    style={{
                      width: '100%',
                    }}
                  />
                </a>
              </div>
            ) : null}
             <div className="mc-table-container">
              <table width="100%" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td align="center" style={{ padding: '10px' }}>
                      <table width="100%" cellSpacing="0" cellPadding="0">
                        <tbody>
                          <tr>
                            <td width="120" align="center" style={{ paddingRight: '10px' }}>
                              <a href={decodedStoreURL}>
                                <img
                                  className="store-logo"
                                  src={deal.retail_store_logo_url}
                                  alt={deal.merchant_name}
                                  width="120"
                                  style={{ borderRadius: '60px'}}
                                />
                              </a>
                            </td>
                            <td align="center">
                              <a href={decodedStoreURL}>
                                <h2
                                  className="promotion-type"
                                  style={{
                                    textTransform: 'uppercase',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    padding: '10px',
                                    margin: '0',
                                  }}
                                >
                                  {deal.merchant_name + ' - ' + deal.promotion_type}
                                </h2>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" style={{ padding: '0px 10px 10px 10px' }}>
                      <p style={{ padding: '10px', margin: '0' }}>{deal.description}</p>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" style={{ padding: '10px' }}>
                      <a
                        href={decodedStoreURL}
                        style={{
                          padding: '12px 38px',
                          margin: '0px 10px 20px 10px',
                          backgroundColor: '#000000',
                          color: '#ffffff',
                          outline: 'none',
                          borderRadius: '34px',
                          fontSize: '24px',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          textDecoration: 'none',
                          display: 'inline-block',
                        }}
                      >
                        SHOP NOW
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


            {/* <div className="mc-table-container">
              <table width="100%" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td align="center" style={{ padding: '10px' }}>
                      <table width="100%" cellSpacing="0" cellPadding="0">
                        <tbody>
                          <tr>
                            <td width="120" align="center" style={{ paddingRight: '10px' }}>
                              <a href={decodedStoreURL}>
                                <img
                                  className="store-logo"
                                  src={deal.retail_store_logo_url}
                                  alt={deal.merchant_name}
                                  width="120"
                                  style={{ borderRadius: '60px' }}
                                />
                              </a>
                            </td>
                            <td align="center">
                              <a href={decodedStoreURL}>
                                <h2
                                  className="promotion-type"
                                  style={{
                                    textTransform: 'uppercase',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    padding: '10px',
                                    margin: '0',
                                  }}
                                >
                                  {deal.merchant_name + ' - ' + deal.promotion_type}
                                </h2>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" style={{ padding: '0px 10px 10px 10px' }}>
                      <p style={{ padding: '10px', margin: '0' }}>{deal.description}</p>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" style={{ padding: '10px' }}>
                      <a
                        href={decodedStoreURL}
                        style={{
                          padding: '12px 38px',
                          margin: '0px 10px 20px 10px',
                          backgroundColor: '#000000',
                          color: '#ffffff',
                          outline: 'none',
                          borderRadius: '34px',
                          fontSize: '24px',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          textDecoration: 'none',
                          display: 'inline-block',
                        }}
                      >
                        SHOP NOW
                      </a>
                    </td>
                  </tr>
                  {/* Second Row - Banner with Logo on Top */}
              {/*    <tr>
                    <td align="center" style={{ padding: '10px' }}>
                      <table width="100%" cellSpacing="0" cellPadding="0">
                        <tbody>
                          <tr>
                            <td style={{ position: 'relative' }}>
                              <a href={decodedStoreURL}>
                                <img
                                  className="deal-banner-image"
                                  src={deal.coupon_banner_image_url}
                                  alt={deal.merchant_name}
                                  width="100%"
                                />
                              </a>
                              <a href={decodedStoreURL} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <img
                                  className="store-logo"
                                  src={deal.retail_store_logo_url}
                                  alt={deal.merchant_name}
                                  width="120"
                                  style={{ borderRadius: '60px' }}
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}

          </div>
      </div>


    </DealWrapper>
  );
}

export default memo(Deal);
