import React, { useEffect, useLayoutEffect, useState, startTransition, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
//import { bindActionCreators } from "redux";
import moment from "moment";
import styled from "styled-components";
import Fuse from "fuse.js";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { Divider } from "@mui/material";
import { isChrome, isMobile } from "react-device-detect";

import { sleep } from "Utils";
import BlackFridayBanner from 'SharedComponents/black-friday-banner';
import ShareStrip from 'SharedComponents/share-strip';
import NotificationBanner from 'SharedComponents/notification-banner';
import GetExtensionStrip from 'SharedComponents/extension-install-strip';
import ToastNotify from 'SharedComponents/toast-notifications';
import log from "Utils/logger";
import AdblockDetector from 'SharedComponents/adblock-detector';
import StoreBoostsList from 'SharedComponents/store-boosts-list';
import StoreTags from 'SharedComponents/store-tags/';
import RightDrawerV2 from "SharedComponents/right-drawerV2";
import ModalDialog from "SharedComponents/modal-dialog";
import ActionButton from "SharedComponents/action-button";

import CONFIG from "../../../config";

import * as ShoppingActions from "Flux/shopping/actions";

import Stores from "./components/stores";
import Deals from "./components/deals";
import Favorites from "./components/favorites"
import Promoted from "./components/promoted";
import Search from "./components/search";
import Tabs from "SharedComponents/tabs";
import Categories from "./components/categories";
import TransactionSignInDialog from "./components/transaction-sign-in-dialog";


import useFetchStores from "SharedHooks/fetch-stores";
import useFetchDeals from "SharedHooks/fetch-deals";
//import useFetchFavoriteShops from "SharedHooks/fetch-favorite-shops";
import useFetchStoreTags from "SharedHooks/fetch-store-tags";
//import useFetchStoreBoostProfiles from "SharedHooks/fetch-store-boost-profiles";
import useFetchCategories from "SharedHooks/fetch-categories";
import useFetchRoundUpsShopperData from 'SharedHooks/fetch-roundups-shopper-data';

import styles from "./index.css";
import favorites from "./components/favorites";
import { initial } from "lodash";
//import notificationBanner from "../../components/notification-banner";

const ShoppingWrapper = styled.section`
  ${styles}
`;

const Shopping = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  console.log('Shopping component triggered ');

  const dispatch = useDispatch();
  const location = useLocation();

  const shopping = useSelector((state) => state.shopping);
  const authentication = useSelector((state) => state.authentication);
  const isExtensionInstalled = useSelector((state) => state.app.isExtensionInstalled);
  const user = useSelector((state) => state.authentication.user);

  const { fetchStores } = useFetchStores();
  const { fetchCategories } = useFetchCategories();
  const { fetchDeals } = useFetchDeals();
  const { fetchStoreTags } = useFetchStoreTags();
  //replace fetchRoundUpsShopperData with broadcast ably when ready. broadcast
  //ably will do the fetch.
  const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
  //const { checkIsExtensionInstalled } = useCheckIsExtensionInstalled();

  const initialisePromotedDealsList = new Fuse(shopping.promotedDeals, {
    tokenize: true,
    threshold: 0.0,
    keys: ["merchant_name", "categories_string", "description", "code"]
  });

  const initialiseDealsList = new Fuse(shopping.deals, {
    tokenize: true,
    threshold: 0.0,
    keys: ["merchant_name", "categories_string", "description", "code"]
  });

  const initialiseStoresList = new Fuse(shopping.stores, {
    tokenize: true,
    threshold: 0.1,
    matchAllTokens: true,
    minMatchCharLength: 3,
    keys: [ 
      { name: "merchant_name", weight: 0.4 }, 
      { name: "tags", weight: 0.3 },
      { name: "categories_string", weight: 0.2 },
      { name: "merchant_description", weight: 0.1 } 
    ]
  });

  const initialiseFavoritesList = new Fuse(shopping.favorites, {
    tokenize: true,
    threshold: 0.1,
    matchAllTokens: true,
    minMatchCharLength: 3,
    keys: [ 
      { name: "merchant_name", weight: 0.4 }, 
      { name: "tags", weight: 0.3 },
      { name: "categories_string", weight: 0.2 },
      { name: "merchant_description", weight: 0.1 } 
    ]
  });

  const initialCategory = searchParams.get("category")
    ? decodeURIComponent(searchParams.get("category"))
    : null;
  const initialSearch = searchParams.get("search")
    ? decodeURIComponent(searchParams.get("search"))
    : null;
  const initialTab = searchParams.get("tab");
  const initialCouponId = searchParams.get("coupon_id")
    ? decodeURIComponent(searchParams.get("coupon_id"))
    : null;
  const initialRetailStoreId = searchParams.get("retail_store_id")
    ? decodeURIComponent(searchParams.get("retail_store_id"))
    : null;

  const retailStoreIdRef = useRef(initialRetailStoreId);

  const initialSearchTerm = initialCategory ? initialCategory : initialSearch;

  const [activeTab, setActiveTab] = useState(initialTab || "stores");
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory || null);

  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(false);

  const [tagsList, setTagsList] = useState(
    shopping?.tags?.map((item) => item.tag) || [] 
  );

  const [stores, setStores] = useState(shopping.stores);
  const [storesList, setStoresList] = useState(initialiseStoresList);
  const [storesVisibleList, setStoresVisibleList] = useState(storesList.list);
  const [storesNumberSearchResults, setStoresNumberSearchResults] = useState(storesList.list.length);
  const [storesVisibleListPrevLength, setStoresVisibleListPrevLength] = useState(0);

  const [favorites, setFavorites] = useState(shopping.favorites);
  const [favoritesList, setFavoritesList] = useState(initialiseFavoritesList);
  const [favoritesVisibleList, setFavoritesVisibleList] = useState(favoritesList.list);
  const [favoritesNumberSearchResults, setFavoritesNumberSearchResults] = useState(favoritesList.list.length);
  const [favoritesVisibleListPrevLength, setFavoritesVisibleListPrevLength] = useState(0);

  const [deals, setDeals] = useState(shopping.deals);
  const [dealsList, setDealsList] = useState(initialiseDealsList);
  const [dealsVisibleList, setDealsVisibleList] = useState(dealsList.list);
  const [dealsNumberSearchResults, setDealsNumberSearchResults] = useState(dealsList.list.length);
  const [dealsVisibleListPrevLength, setDealsVisibleListPrevLength] = useState(0);

  const [promotedDeals, setPromotedDeals] = useState(shopping.promotedDeals);
  const [promotedDealsList, setPromotedDealsList] = useState(initialisePromotedDealsList);
  const [promotedDealsVisibleList, setPromotedDealsVisibleList] = useState(promotedDealsList.list);
  const [promotedDealsNumberSearchResults, setPromotedDealsNumberSearchResults] = useState(promotedDealsList.list.length);
  const [promotedDealsVisibleListPrevLength, setPromotedDealsVisibleListPrevLength] = useState(0);

  const [sharedItem, setSharedItem] = useState(false);
    

  const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [showModalDialog, setShowModalDialog] = useState(false);
  const [clickedStore, setClickedStore] = useState(null);

  const [localIsExtensionInstalled, setLocalIsExtensionInstalled] = useState(isExtensionInstalled);

  const [currentPromotion, setCurrentPromotion] = useState(shopping.currentPromotion);

 
  useEffect(() => {
    //useEFFECT FOr testing only. delete for prd.
    console.log('shopping - useEffect - *** Main component mounted');

    return () => {
      console.log('shopping - useEffect - *** Main component unmounted');
    };
  }, []);

  useEffect(() => {
    console.log('shopping - useEffect - *** extensionInstalled updated');
    console.log('shopping - useEffect - *** extensionInstalled updated - isExtensionInstalled: ', isExtensionInstalled);
    console.log('shopping - useEffect - *** extensionInstalled updated - localIsExtensionInstalled: ', localIsExtensionInstalled);
    if (isExtensionInstalled !== localIsExtensionInstalled) {
      console.log('shopping - useEffect - *** extensionInstalled updated - EXECUTING');
      setLocalIsExtensionInstalled(isExtensionInstalled);
    }
  }, [isExtensionInstalled]);

  useEffect (() => {

    //useEffec for testing only. Remove for prod.

  console.log('shopping - useEffect - *** VISIBLE LIST UPDATED');
  //console.log('shopping - useEffect - *** VISIBLE LIST UPDATED - storesVisibleList: ', storesVisibleList);
  console.log('shopping - useEffect - *** VISIBLE LIST UPDATED - storesVisibleListPrevLength: ', storesVisibleListPrevLength);
  console.log('shopping - useEffect - *** VISIBLE LIST UPDATED - storesVisibleList.length: ', storesVisibleList.length);

  console.log('shopping - useEffect - *** VISIBLE LIST UPDATED - favoritesVisibleListPrevLength: ', favoritesVisibleListPrevLength);
  console.log('shopping - useEffect - *** VISIBLE LIST UPDATED - favoritesVisibleList.length: ', favoritesVisibleList.length);

  console.log('shopping - useEffect - *** VISIBLE LIST UPDATED - dealsVisibleListPrevLength: ', dealsVisibleListPrevLength);
  console.log('shopping - useEffect - *** VISIBLE LIST UPDATED - dealsVisibleList.length: ', dealsVisibleList.length);

  console.log('shopping - useEffect - *** VISIBLE LIST UPDATED - promotedDealsVisibleListPrevLength: ', promotedDealsVisibleListPrevLength);
  console.log('shopping - useEffect - *** VISIBLE LIST UPDATED - promotedDealsVisibleList.length: ', promotedDealsVisibleList.length);

  }, [storesVisibleList, favoritesVisibleList, dealsVisibleList, promotedDealsVisibleList]);

  useEffect(() => {
    console.log('shopping - useEffect - user - tiggered by - user signed in or out user: ', user);

      if (!user && favoritesVisibleList?.length > 0) {
          console.log('shopping - useEffect - user -EXECUTING No user lets remove fav stores as member signed out');
          dispatch(ShoppingActions.removeFavoriteShops());
      }

  }, [user]);

  useEffect(() => {
    console.log('shopping useEffect - updateStores due to shopping.stores or shopping.favorites change');

    if (   shopping.stores?.length > 0 && shopping.stores?.length !== stores?.length
        || shopping.favorites && shopping.favorites?.length !== favorites?.length
      ) {
      console.log('shopping - useEffect - updateStores - EXECUTING');
      console.log('shopping - useEffect - updateStores - with shopping.stores.length: ', shopping.stores.length);
      console.log('shopping - useEffect - updateStores - with stores.length: ', stores.length);
      console.log('shopping - useEffect - updateStores - with shopping.favorites.length: ', shopping.favorites.length);
      console.log('shopping - useEffect - updateStores - with favorites.length: ', favorites.length);
      const reInitialiseStoresList = new Fuse(shopping.stores, {
        tokenize: true,
        threshold: 0.1,
        matchAllTokens: true,
        minMatchCharLength: 3,
        keys: [
          { name: "merchant_name", weight: 0.4 },
          { name: "tags", weight: 0.3 },
          { name: "categories_string", weight: 0.2 },
          { name: "merchant_description", weight: 0.1 },
        ],
      });
      
      setStoresList(reInitialiseStoresList);
      setStores(shopping.stores);

      setStoresVisibleListPrevLength((prevStoresVisibleList) => {
        return prevStoresVisibleList?.length ?? 0;
      });

      const reInitialiseFavoritesList = new Fuse(shopping.favorites, {
        tokenize: true,
        threshold: 0.1,
        matchAllTokens: true,
        minMatchCharLength: 3,
        keys: [
          { name: "merchant_name", weight: 0.4 },
          { name: "tags", weight: 0.3 },
          { name: "categories_string", weight: 0.2 },
          { name: "merchant_description", weight: 0.1 },
        ],
      });

      setFavoritesList(reInitialiseFavoritesList);
      setFavorites(shopping.favorites);

      setFavoritesVisibleListPrevLength((prevFavoritesVisibleList) => {
        return prevFavoritesVisibleList?.length ?? 0;
      });
    }

  }, [shopping.stores, shopping.favorites]);

  useEffect(() => {

    const updatedFavoritesVisibleList = searchTerm?.length ? favoritesList.search(searchTerm) : favoritesList.list;
    setFavoritesVisibleList(updatedFavoritesVisibleList);

  }, [favorites]);

  useEffect(() => {
    console.log ('shopping - useEffect - sharedItem set shared item triggered by - stores update :',stores);
    const updatedVisibleList = searchTerm?.length ? storesList.search(searchTerm) : storesList.list;
    setStoresVisibleList(updatedVisibleList);

    if (stores?.length >0 && !!initialRetailStoreId) {
      
      console.log('shopping - useEffect -  set shared item - EXECUTING');
      console.log('shopping - useEffect -  in cDU is : ', sharedItem);
      const sharedRetailStore = stores.find(
        store => store.retail_store_id === initialRetailStoreId 
      );
      onSetSharedItem(sharedRetailStore, "store");
    }

  }, [stores]);

 
  useEffect(() => {
    console.log('shopping - useEffect - updateDeals - triggered');
    
    if (shopping.deals?.length > 0 && shopping.deals.length !== deals.length) {
      console.log('shopping - useEffect - updateDeals - EXECUTING');

      const updatedDealsList = new Fuse(shopping.deals, {
        tokenize: true,
        threshold: 0.0,
        keys: ["merchant_name", "categories_string", "description", "code"],
      });
      setDealsList(updatedDealsList);
      setDeals(shopping.deals);
    }

  }, [shopping.deals]);

  useEffect(() => {
    const updatedDealsVisibleList = searchTerm?.length ? dealsList.search(searchTerm) : dealsList.list;
    setDealsVisibleList(updatedDealsVisibleList);
  }, [deals]);

  useEffect(() => {
    console.log('shopping - useEffect - updatePromotedDeals - triggered');

    if (shopping.promotedDeals?.length > 0 && shopping.promotedDeals.length !== promotedDeals.length ) {
      console.log('shopping - useEffect - updatePromotedDeals - EXECUTING');
      const updatedPromotedDealsList = new Fuse(shopping.promotedDeals, {
        tokenize: true,
        threshold: 0.0,
        keys: ["merchant_name", "categories_string", "description", "code"]
      });
      setPromotedDealsList(updatedPromotedDealsList);
      setPromotedDeals(shopping.promotedDeals);
    }

  }, [shopping.promotedDeals]);

  useEffect(() => {

    const updatedPromotedDealsVisibleList = searchTerm?.length ? promotedDealsList.search(searchTerm) : promotedDealsList.list;
    setPromotedDealsVisibleList(updatedPromotedDealsVisibleList);
  }, [promotedDeals]);


  useEffect(() => {
    console.log('shopping  - useEffect - updateTags triggered by shopping.tags: ', shopping.tags);
    console.log('shopping  - useEffect - updateTags shopping.tags.length : ', shopping.tags.length);
    console.log('shopping  - useEffect - updateTags tagsList.length : ', tagsList.length);
    if (shopping?.tags?.length > 0 && shopping.tags.length !== tagsList.length) {
      console.log('shopping - useEffect - updateTags - EXECUTING - with shopping.tags: ', shopping.tags);
      const newTagsList = shopping.tags.map((item) => item.tag);
      console.log('shopping - useEffect - updateTags - newTagsList: ', newTagsList);
      setTagsList(newTagsList);
    }
  }, [shopping.tags]); 


  useEffect(() => {

    const currentUrlTab = new URL(window.location).searchParams.get("tab");

    console.log('shopping - useEffect - activeTab triggered with NEW activeTab: ', activeTab);
    console.log('shopping - useEffect - activeTab triggered with OLD currentUrlTab: ', currentUrlTab);

    if (currentUrlTab !== activeTab) {
      console.log('shopping - useEffect - activeTab EXECUTING to set url to activeTab: ', activeTab);
      //navigate(`?tab=${encodeURIComponent(activeTab)}`, { replace: true });  
      //setSearchParams(`?tab=${encodeURIComponent(activeTab)}`, { replace: true });
      //could not get setSearchParams to update without causing an unmount and remount :(.
      const url = new URL(window.location);
      url.searchParams.set('tab', activeTab);
      window.history.replaceState({}, '', url);

    }


  }, [activeTab]); // searchParams, navigate]);


  useEffect(() => {
    console.log('shopping - useEffect - onSearchTermChanged triggered with searchTerm: ', searchTerm);
    if ( searchTerm?.length > 0){
      console.log('shopping - useEffect - onSearchTermChanged - EXECUTING searchTerm is greater than 0');
      //const newSearchParams = new URLSearchParams(searchParams);
      //newSearchParams.set('search', searchTerm); // Use the updated searchTerm
      //setSearchParams(newSearchParams);
      //setSearchParams(`?search=${encodeURIComponent(searchTerm)}`, { replace: true });
      const newSearchParams = new URLSearchParams(window.location.search);
      newSearchParams.set('search', searchTerm); // Update the 'search' parameter
      const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
      window.history.replaceState({}, '', newUrl); 

      // const url = new URL(window.location);
      // url.searchParams.set('search', searchTerm);
      // window.history.replaceState({}, '', url);
      console.log('shopping - useEffect - onSearchTermChanged  - Lets set  searchParams to searchTerm: ', newSearchParams);
    } else if ( searchTerm?.length === 0 ) {
      console.log('shopping - useEffect - onSearchTermChanged - EXECUTING searchTerm is 0');
      const newSearchParams = new URLSearchParams(window.location.search);
      newSearchParams.delete('search'); // Remove the 'search' parameter
      const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
      window.history.replaceState({}, '', newUrl); 
      console.log('shopping - useEffect - onSearchTermChanged  - Lets set  searchParams to searchTerm: ', newSearchParams);
    }
    
      
  }, [searchTerm ]); 

  useEffect(() => {
    console.log('shopping - useEffect - sharedItem Updated with sharedItem: ', sharedItem);
    if (sharedItem){
      console.log('shopping - useEffect - sharedItem Updated - EXECUTING with sharedItem: ', sharedItem);
      setIsRightDrawerOpen(true);
      const newSearchParams = new URLSearchParams(window.location.search);
      newSearchParams.set('retail_store_id', sharedItem.item.retail_store_id);
      const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
    } else {
      //wanto to check if retail_store_id is a param before deleting. initial run might
      //try to delete non existant param.
      const newSearchParams = new URLSearchParams(window.location.search);
      newSearchParams.delete('retail_store_id');
      const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
      setIsRightDrawerOpen(false);
    }

  }, [sharedItem]);

  useEffect   (() => {
    console.log('shopping - useEffect - isRightDrawerOpen triggered with isRightDrawerOpen: ', isRightDrawerOpen);
    if (!isRightDrawerOpen) {
      console.log('shopping - useEffect - isRightDrawerOpen - EXECUTING showModalDialog is true');
      setTimeout(() => {
        setSharedItem(null);
      }, 500);
    }
  }, [isRightDrawerOpen]);


  // useEffect (() => {
  //   console.log('shopping - useEffect - onSearchTermChanged triggered with searchTerm: ', searchTerm);
  //   if (searchTerm?.length > 0) {
  //     console.log('shopping - useEffect - search Term in initial url, set up visible lists');
      
  //     const initialVisibleList = storesList.search(searchTerm);
  //     setStoresVisibleList(initialVisibleList);

  //     const initialFavoritesVisibleList = favoritesList.search(searchTerm);
  //     setFavoritesVisibleList(initialFavoritesVisibleList);

  //     const initialDealsVisibleList = dealsList.search(searchTerm);
  //     setDealsVisibleList(initialDealsVisibleList);

  //     const initialPromotedDealsVisibleList = promotedDealsList.search(searchTerm);
  //     setPromotedDealsVisibleList(initialPromotedDealsVisibleList);

  //   }


  // }, []);

  
  const onActiveTabChanged = ( newActiveTab ) => {
    console.log('shopping - function - onActiveTabChanged triggered with newActiveTab: ', newActiveTab);
    setActiveTab(newActiveTab);
  };


  const updateCountOverInterval = (countVariable, targetValue, initialValue, setState) => {
    const intervalDuration = 30; // 
    const steps = 10; // Number of steps
    const stepValue = (targetValue - initialValue) / steps;

    let currentStep = 0;
    const intervalId = setInterval(() => {
        const currentValue = initialValue + stepValue * currentStep;
        setState(prevState => ({ ...prevState, [countVariable]: Math.round(currentValue) }));

        currentStep++;

        if (currentStep > steps) {
            clearInterval(intervalId);
            setState(prevState => ({ ...prevState, [countVariable]: targetValue }));
        }
    }, intervalDuration / steps);
  };


  const onCloseRightDrawer = (setState, AppActions, activeTab) => {
      console.log('shopping - function - onCloseRightDrawer triggered')
      setIsRightDrawerOpen(false);
  };

  const onCloseSignInDialog = () => {
    setIsSignInDialogOpen(false);
  };

  const onCloseModalDialog = () => {
    setShowModalDialog(false);
    setClickedStore(null);
  };


  const onProceedWithTransaction = async (delay = null) => {
    if (delay) {
      await sleep(delay);
    }

    if (!transaction) return;

    //const { transaction } = this.state;
    const { store, type } = transaction;

    console.log("[transaction]", transaction);

    const { merchant_name, retail_store_logo_url, rate } = store;
    const queryStringObject = { merchant_name, retail_store_logo_url, rate };
    const queryString = Object.keys(queryStringObject)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(store[key])}`
      )
      .join("&");

    try {
      const newTab = window.open("", "_blank");
      console.log("[newTab]", newTab);
      newTab.location = `${window.location.origin}/transaction-loading?${queryString}`;

      const getForwardingUrlResult = await dispatch(ShoppingActions.getForwardingUrl(store,type));

      // we put a timeout here to make sure the target store holding screen is seen long enough
      window.setTimeout(() => {
        console.log("[newTab]", newTab);
        newTab.location = getForwardingUrlResult.data.forwarding_url;
        log({
          event: "ShopNow",
          type,
          //shopper_id: authentication.user.shopper_id,
          retail_store_id: store.retail_store_id,
          ...store,
          ...(type === "deal" ? { coupon_id: store.coupon_id } : {})
        });
      }, 2000);
      setClickedStore(store);
      setShowModalDialog(true);
    } catch (exception) {
      log({
        event: "System-Error",
        source: "Proceed With Transaction",
        info: exception,
        ...exception,
        ...store,
        //shopper_id: authentication.user.shopper_id
      });
      console.log("[exception caught]", exception);
    } finally {
      setTransaction(null);
      setShowModalDialog(false);
    }
  };

  const onSearchTermChanged = ({ target }) => {
    console.log('onSearchTermChanged target.value is: ', target.value);
    console.log('onSearchTermChanged storesList is: ', storesList);
    //console.log('onSearchTermChanged storesList.list is: ', storesList.list);
    console.log('onSearchTermChanged storesVisibleList is: ', storesVisibleList);
    
    setSearchTerm(target.value); 
    setStoresVisibleList(target.value.length ? storesList.search(target.value) : storesList.list);
    setFavoritesVisibleList(target.value.length ? favoritesList.search(target.value) : favoritesList.list);
    setDealsVisibleList(target.value.length ? dealsList.search(target.value) : dealsList.list);
    setPromotedDealsVisibleList(target.value.length ? promotedDealsList.search(target.value) : promotedDealsList.list);
    //setSelectedCategory(null); 

  }

  const onClearSearch = () => {
    setSearchTerm(""); // Update searchTerm
    //setSelectedCategory(null); // Update selectedCategory
    setStoresVisibleList(storesList.list); // Update storesVisibleList
    setFavoritesVisibleList(favoritesList.list); // Update favoritesVisibleList
    setDealsVisibleList(dealsList.list); // Update dealsVisibleList
    setPromotedDealsVisibleList(promotedDealsList.list);  
    //setSearchParams(`?tab=${encodeURIComponent(activeTab)}`)
    //navigate(`${location.pathname}?tab=${encodeURIComponent(activeTab)}`);
    
  };

    
  const onSetSharedItem = (item, type) => {
    console.log('shopping - function - onSetSharedItem triggered with item: ', item);
    console.log('shopping - function - onSetSharedItem triggered with type: ', type);

      setSharedItem({ type, item });
      if (retailStoreIdRef.current) {
        console.log('shopping - function - onSetSharedItem - initialRetailStoreId set to null');
        retailStoreIdRef.current = null;
      }
    
  };


  const onShopNow = async (store, type = "shop") => {
    if (authentication.user) {
      const { merchant_name, retail_store_logo_url, rate } = store;
      const queryStringObject = { merchant_name, retail_store_logo_url, rate };
      const queryString = Object.keys(queryStringObject)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(store[key])}`
        )
        .join("&");

      try {
        const newTab = window.open("", "_blank");
        newTab.location = `${window.location.origin}/transaction-loading?${queryString}`;

        const getForwardingUrlResult = await dispatch(ShoppingActions.getForwardingUrl(
          store,
          type
        ));
        window.setTimeout(() => {
          newTab.location = getForwardingUrlResult.data.forwarding_url;
          console.log({
            event: "ShopNow",
            type,
            shopper_id: authentication.user.shopper_id,
            retail_store_id: store.retail_store_id,
            ...store,
            ...(type === "deal" ? { coupon_id: store.coupon_id } : {})
          });
          log({
            event: "ShopNow",
            type,
            //shopper_id: authentication.user.shopper_id,
            retail_store_id: store.retail_store_id,
            ...store,
            ...(type === "deal" ? { coupon_id: store.coupon_id } : {})
          });
        }, 2000);

        setShowModalDialog(true);
        setClickedStore(store);

      } catch (exception) {
        log({
          event: "ShopNowError",
          ...exception,
          ...store,
          type,
          //shopper_id: authentication.user.shopper_id,
        });
        console.log(exception);
      }
    } else {
      setIsSignInDialogOpen(true);
      setTransaction({ store, type });
    }
  }

  const setFavShops = async (retailStoreId, favAction) => {
    try {
      if (favAction === "userAddFavShop") {
        dispatch(await ShoppingActions.setFavoriteOnStore(retailStoreId));
      } else if (favAction === "userDeleteFavShop") {
        await dispatch(ShoppingActions.unSetFavoriteOnStore(retailStoreId));
      }
    } catch (exception) {
      log({
        event: "System-Error",
        source: "setFavShopps",
        info: exception,
        ...exception,
        //shopper_id: authentication.user.shopper_id,
      });
      console.log(exception);
    } finally {
      dispatch(ShoppingActions.addFavoriteShops());
      if (favAction === "userAddFavShop") {
        dispatch(ShoppingActions.insertFavoriteShop(retailStoreId));
        console.log(['calling toaster to add store now']);
        ToastNotify({
          message: " Added to your faves",
          notificationType: "success",
          myPromise: null,
          loadingMessage: null,
          loadingErrorMessage: null
        });
      } else if (favAction === "userDeleteFavShop") {
        dispatch(ShoppingActions.deleteFavoriteShop(retailStoreId));
        ToastNotify({
          message: "Removed from your faves",
          notificationType: "success",
          myPromise: null,
          loadingMessage: null,
          loadingErrorMessage: null
        });
      }
    }
  };

    return (
      <ShoppingWrapper isRightDrawerOpen={isRightDrawerOpen}>
        <TransactionSignInDialog
          open={isSignInDialogOpen}
          onCloseSignInDialog={onCloseSignInDialog}
          onAfterSignIn={onProceedWithTransaction}
        />
        <RightDrawerV2
          open={isRightDrawerOpen}
          onClose={onCloseRightDrawer}
          sharedItem={sharedItem}
          onShopNow={onShopNow}
          onSearchTermChanged={onSearchTermChanged}
          deals={deals}
        />
        <div className="shopping-container">
        {
            (!authentication.user)? (
              null
             ) : (
              <div className="shopping-notification">
                <AdblockDetector />
              </div>
            )
        }
          <div className="shopping-header-strip">
            { (isChrome && !isMobile && !localIsExtensionInstalled) ? (
                <GetExtensionStrip />
              ) : (!authentication.user) ? (
                null
              ) : <ShareStrip />
            }
          </div>

          <div className="main-shopping-content">

            <div className="search-stores-listing">
            
              {
                (!authentication.user)?  (
                  null ) : (
                    (!authentication.user.signup_method)? (
                      <NotificationBanner 
                        title="Notification"
                        description="Please Logout and Login to update your settings"
                        subtext="Your account may not fucntion correctly until you do so"
                      />
                    ) : null)
              }      
            

              <BlackFridayBanner />
              <div className="search-tabs-pagination-container">
                <Search
                  value={searchTerm}
                  onChange={onSearchTermChanged}
                  onClearSearch={onClearSearch}
                />
                <StoreTags
                  storeTags={tagsList}
                  onSearchTermChanged ={onSearchTermChanged}
                  maxRows={2}
                  hasFetchedStoreTags={(tagsList.length > 0) ? true : false}
                  onClose = { null }
                />
                <Tabs
                  tabs={[
                    {
                      label: "Shops",
                      value: "stores",
                      //count: storesVisibleList.length ? storesNumberSearchResults : 0
                      count: storesVisibleList.length ? storesVisibleList.length : 0
                    },
                    {
                      label: "Faves",
                      value: "favorites",
                      //count: favoritesVisibleList.length ? favoritesNumberSearchResults : 0
                      count: favoritesVisibleList.length ? favoritesVisibleList.length : 0
                    },
                    {
                      label: "Offers",
                      value: "deals",
                      //count: dealsVisibleList.length ? dealsNumberSearchResults : 0
                      count: dealsVisibleList.length ? dealsVisibleList.length : 0
                    },
                    {
                      label: currentPromotion ? currentPromotion.name : "Promoted",
                      value: "promoted",
                      //count: promotedDealsVisibleList.length ? promotedDealsNumberSearchResults : 0
                      count: promotedDealsVisibleList.length ? promotedDealsVisibleList.length : 0
                    }
                  ]}//]).filter(tab => tab !== null )}
                  onActiveTabChanged={onActiveTabChanged}
                  activeTab={activeTab}
                />
              </div>
              {activeTab === "stores" ? (
                <Stores
                  items={storesVisibleList}
                  searchTerm={searchTerm}
                  selectedCategory={selectedCategory}
                  onShopNow={onShopNow}
                  onSetSharedItem={onSetSharedItem}
                  setFavShops={setFavShops}
                />
                ) : activeTab === "favorites" ? (
                <Favorites
                  items={favoritesVisibleList}
                  searchTerm={searchTerm}
                  selectedCategory={selectedCategory}
                  onShopNow={onShopNow}
                  onSetSharedItem={onSetSharedItem}
                  setFavShops={setFavShops}
                />
                ) : activeTab === "deals" ? (
                <Deals
                  items={dealsVisibleList}
                  searchTerm={searchTerm}
                  selectedCategory={selectedCategory}
                  onShopNow={onShopNow}
                  onSetSharedItem={onSetSharedItem}
                />
              ) : (
                    <Promoted
                      items={promotedDealsVisibleList}
                      searchTerm={searchTerm}
                      selectedCategory={selectedCategory}
                      onShopNow={onShopNow}
                      onSetSharedItem={onSetSharedItem}
                      currentPromotion={currentPromotion}
                    />
                  )}
            </div>
{/*             <RightDrawerV2
              open={isRightDrawerOpen}
              onClose={onCloseRightDrawer}
              sharedItem={sharedItem}
              onShopNow={onShopNow}
              onSearchTermChanged={onSearchTermChanged}
              //isFetchingSharedItem={isFetchingSharedItem}
              deals={deals}
              //hasFetchedStoreTags={true}
              //should this be hasFetchedStoreTags={tagsList.length > 0}
            /> */}
            {showModalDialog ? (
              <ModalDialog
                size="sm"
                open={showModalDialog}
                onClose={onCloseModalDialog}
                contentComponent={
                  <div
                    style={{
                      padding: 24,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column"
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "15px 0 20px"
                      }}
                    >
                      <img
                        src={clickedStore.retail_store_logo_url}
                        alt={clickedStore.merchant_name}
                        style={{ width: 100 }}
                      ></img>
                    </div>
                    <div
                      className="flex-container"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginBottom: 15
                      }}
                    >
                      <div
                        className="flex-container"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "0 10px 0 0",
                          backgroundColor: "#c6ffc6",
                          padding: 3,
                          borderRadius: "50%"
                        }}
                      >
                        <SvgIcon>
                          <path
                            fill="green"
                            d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                          />
                        </SvgIcon>
                      </div>
                      <span style={{ fontSize: 20 }}>
                        You visited {clickedStore.merchant_name}
                      </span>
                    </div>
                    <div className="shopping-notification">
                      <AdblockDetector />
                    </div>
                    <Divider />
{/*                       <StoreBoostsList
                        retailStore={clickedStore}
                        retailStoreId={clickedStore.retail_store_id}
                      /> */}
                    {/* <p>
                      {clickedStore.merchant_name} will {clickedStore.rate}.
                    </p> */}
                    <p>
                      We confirm purchases within 24 hrs for 95% of stores.
                    </p>
                    <p>
                    Up to 5 business days for 5% of stores.
                    </p>


                    {isMobile ? (
                      <ActionButton
                        text="Dismiss"
                        large={true}
                        onClick={onCloseModalDialog}
                      />
                    ) : null}
                  </div>
                }
              />
            ) : null}
          </div>
        </div>
      </ShoppingWrapper>
    );
  //}
};

// Shopping.propTypes = {
//   roundups: PropTypes.object.isRequired,
//   authentication: PropTypes.object.isRequired,
//   app: PropTypes.object.isRequired,
// };

export default Shopping;
