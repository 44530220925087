export default ({ theme }) => {
return  `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  

  .store-details-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;


    .store-details {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      
      
      .store-logo-container {
        width: 130px;
        height: auto;
        display: flex;
        box-shadow: 0 0 0 1px ${theme.borderColor};
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        margin: 2px 5px 2px 2px;
        .store-logo {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .deals-and-summary-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* width: calc(100% - 150px); */
        /* padding: 10px; */
        align-items: flex-start;
        justify-content: center;
        /* min-height: 110px; */

        .deals-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .summary {
          margin: 10px 0 20px;
          }
        }
    }

    .rate-shopnow-container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .shop-now {
        padding: 10px;
        background-color: ${theme.darkSecondaryColor};
        color: ${theme.secondaryTextColor};
        border-radius: 16.5px;
        align-self: center;
        outline: none;

        &:hover {
          background-color: ${theme.lightSecondaryColor};
        }
      }
    }

    .store-rate {
      text-transform: capitalize;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${theme.darkSecondaryColor};
    }

    .summary {
      margin: 10px 0 20px;
    }
`;
};
