import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

//import CONFIG from "Config";

import useFetchPayments from 'SharedHooks/fetch-payments';

import * as RoundUpsTransactionsActions from 'Flux/transactions-round-ups/actions';

const useFetchRoundUpsTransactions = () => {
  console.log ("*** ** fetchRoundUpsTransactions: hook triggered")
  const isFetchingRoundUpsTransactionsRef = useRef(false);
  const hasFetchedRoundUpsTransactionsRef = useRef(false);
  const dispatch = useDispatch();
  //const roundups = useSelector(state => state.roundups);
  
  const storedRoundUpsTransactions = useSelector(state => state.roundUpsTransactions.roundUpsTransactions);
  
  const user = useSelector(state => state.authentication.user);

  const {  fetchPayments } = useFetchPayments();
  
  const fetchRoundUpsTransactions = async () => {
    isFetchingRoundUpsTransactionsRef.current = true;
    try {
      // if (!storedRoundUpsTransactions || !storedRoundUpsTransactions.length || !user) {
      //   console.log('*** ** fetchRoundUpsTransactions: exit function as no storedRoundUpsTransactions or no user');
      //   isFetchingRoundUpsTransactionsRef.current = false;
      //   return;
      // }
      const result = await dispatch(RoundUpsTransactionsActions.fetchRoundUpsTransactions());
      //const newRoundUpsTransactions = result.data.data;

     console.log('*** ** fetchRoundUpsTransactions: result: ', result);

     const newRoundUpsTransactions = result.data.data;
     
      console.log('*** ** fetchRoundUpsTransactions: storedRoundUpsTransactions: ', storedRoundUpsTransactions)
      console.log ('*** ** fetchRoundUpsTransactions: newRoundUpsTransactions: ', newRoundUpsTransactions);
      
      //console.log('*** ** fetchRoundUpsTransactions: JSON.stringify(storedFavouriteShops): ', JSON.stringify(storedRoundUpsTransactions));
      //console.log ('*** ** fetchRoundUpsTransactions: JSON.stringify(newRoundUpsTransactions): ', JSON.stringify(newRoundUpsTransactions));
      
      if (shouldUpdateStore(newRoundUpsTransactions, storedRoundUpsTransactions)) {
        console.log('*** ** fetchRoundUpsTransactions: DIFFERENCE - should update store');
        dispatch(RoundUpsTransactionsActions.setRoundUpsTransactions(newRoundUpsTransactions));
      }
    

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch RoundUpsTransactions",
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingRoundUpsTransactionsRef.current = false;
      hasFetchedRoundUpsTransactionsRef.current = true;
    }
  };

  function shouldUpdateStore(newRoundUpsTransactions, storedRoundUpsTransactions) {

    // if (!isEqual(newRoundUpsTransactions, storedRoundUpsTransactions)) {
    //   return true; // Differences found
    // } 

    if (newRoundUpsTransactions.length !== storedRoundUpsTransactions.length) {
      return true; // Differences found
    }

  
    return false; // No differences found
  }

  useEffect(() => {
    if ( user
        && !isFetchingRoundUpsTransactionsRef.current
        && !hasFetchedRoundUpsTransactionsRef.current) {
      fetchRoundUpsTransactions();
    }
  }, []);

  useEffect(() => {
    if ( user
        && !isFetchingRoundUpsTransactionsRef.current
        && hasFetchedRoundUpsTransactionsRef.current) {
      fetchPayments();
    }
  }, [hasFetchedRoundUpsTransactionsRef.current]);


  return { 
          //isFetchingRoundUpsTransactions: isFetchingRoundUpsTransactionsRef.current,
          // hasFetchedRoundUpsTransactions: hasFetchedRoundUpsTransactionsRef.current,
           fetchRoundUpsTransactions };

};

export default useFetchRoundUpsTransactions;
