export default ({ theme, isOpen,headerLeftPadding }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0;


    >.header {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      box-shadow: ${isOpen ? `0 0 0px 2px ${theme.darkPrimaryColor}` : 'none'};
      align-items: center;
      padding: 0 0 0 ${headerLeftPadding ? headerLeftPadding : '10px'};
       
      @media (max-width: 796px) {
        padding: 0 0 0 10px;
       }

      h3 {
        color: #212121;
      }

      &:hover {
        // border-bottom: solid 2px ${theme.darkPrimaryColor};
        box-shadow: ${isOpen ? `0 0 0px 2px ${theme.darkPrimaryColor}` : `0 0 2px 2px ${theme.darkPrimaryColor}`};
        border-radius: 3px;

        h3 {
          color: #000000;
          font-weight: bold;
        }
      }

      button {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        margin: 0px 10px 0 0;
      }
    }

    >.content {
      height: ${!isOpen ? '0px' : 'auto'} !important;
      transition: height 0.3s;
      margin: 0 !important;
      visibility: ${!isOpen ? 'hidden' : 'visible'};
      background-color: #fff;
      width: calc(100% - 20px) !important;
      padding: 0 10px;
      transition: visibility 1s;
      transition: height 1s;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      box-shadow: 0 0 0px 2px ${theme.darkPrimaryColor};

      .link {
        margin: 0 5px;
        color: ${theme.secondaryColor};
        cursor: pointer;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  `;
