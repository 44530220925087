import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import log from "Utils/logger";

import * as RoundUpsTransactionsActions from 'Flux/transactions-round-ups/actions';

const useFetchPayments = () => {
  console.log ("*** ** fetchPayments: hook triggered");
  const isFetchingPaymentsRef = useRef(false);
  const hasFetchedPaymentsRef = useRef(false);
  const updatingPaymentDataOnContributeNowTransactionsRef = useRef(false);
  const hasUpdatedPaymentDataOnContributeNowTransactionsRef = useRef(false);
  const updatingPaymentDataOnRoundUpsTransactionsRef = useRef(false);
  const hasUpdatedPaymentDataOnRoundUpsTransactionsRef = useRef(false);
  const dispatch = useDispatch();
  //const roundups = useSelector(state => state.roundups);
  
  const storedContributeNowTransactions = useSelector(state => state.roundUpsTransactions.contributeNowTransactions);
  const storedPayments = useSelector(state => state.roundUpsTransactions.payments);
  const storedRoundUpsTransactions = useSelector(state => state.roundUpsTransactions.roundUpsTransactions);

  const storedContributeNowTotalClearing = useSelector(state => state.roundUpsTransactions.contributeNowTotalClearing);
  const storedContributeNowTotalCleared = useSelector(state => state.roundUpsTransactions.contributeNowTotalCleared);
  const storedContributeNowTotalReceived = useSelector(state => state.roundUpsTransactions.contributeNowTotalReceived);
  const storedContributeNowTotalPaid = useSelector(state => state.roundUpsTransactions.contributeNowTotalPaid);

  const storedRoundUpsTotalClearing = useSelector(state => state.roundUpsTransactions.roundUpsTotalClearing);
  const storedRoundUpsTotalCleared = useSelector(state => state.roundUpsTransactions.roundUpsTotalCleared);
  const storedRoundUpsTotalReceived = useSelector(state => state.roundUpsTransactions.roundUpsTotalReceived);
  const storedRoundUpsTotalPaid = useSelector(state => state.roundUpsTransactions.roundUpsTotalPaid);
  
  const user = useSelector(state => state.authentication.user);
  
  const fetchPayments = async () => {
    isFetchingPaymentsRef.current = true;
    try {
      // if (!storedPayments || !storedPayments.length || !user) {
      //   console.log('*** ** fetchPayments: exit function as no storedPayments or no user');
      //   isFetchingPaymentsRef.current = false;
      //   return;
      // }
      const result = await dispatch(RoundUpsTransactionsActions.fetchPayments());
      //const newPayments = result.data.data;

     console.log('*** ** fetchPayments: result: ', result);

     const newPayments = result.data.data;
     
      console.log('*** ** fetchPayments: storedPayments: ', storedPayments)
      console.log ('*** ** fetchPayments: newPayments: ', newPayments);
      
      //console.log('*** ** fetchPayments: JSON.stringify(storedFavouriteShops): ', JSON.stringify(storedPayments));
      //console.log ('*** ** fetchPayments: JSON.stringify(newPayments): ', JSON.stringify(newPayments));

      //console.log(['isFetchingPaymentsRef status: ' , this.({isFetchingPaymentsRef})]);
      
      if (shouldUpdateStore(newPayments, storedPayments)) {
        console.log('*** ** fetchPayments: DIFFERENCE - should update store');
        dispatch(RoundUpsTransactionsActions.setPayments(result.data.data));
      }
    

    } catch (exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "Fetch Payments",
        info: exception,
        ...exception,
      });
    } finally {
      isFetchingPaymentsRef.current = false;
      hasFetchedPaymentsRef.current = true;
    }
  };

  function shouldUpdateStore(newPayments, storedPayments) {

    if (!isEqual(newPayments, storedPayments)) {
      return true;
    } 
    return false;
  }

  useEffect(() => {
    if ( user
        && !isFetchingPaymentsRef.current
        && !hasFetchedPaymentsRef.current) {
      fetchPayments();
    }
  }, []);

  useEffect(() => {
    console.log('*** ** fetchPayments: useEffect hasFetchedPaymentsRef.current:', hasFetchedPaymentsRef.current);
    if (storedPayments 
        && storedPayments.length
        && hasFetchedPaymentsRef.current) {
          if (!updatingPaymentDataOnContributeNowTransactionsRef.current){
            updateContributeNowTransactions();
          }
          if (!updatingPaymentDataOnRoundUpsTransactionsRef.current){
            setPaymentDataOnRoundUpsTransactions();
          }
        }
  }, [hasFetchedPaymentsRef.current]);

  useEffect(() => {
    if (storedContributeNowTransactions && storedContributeNowTransactions.length > 0) {
      updateContributeNowTotals();
    }
  }, [storedContributeNowTransactions]);

  useEffect(() => {
    if (storedRoundUpsTransactions && storedRoundUpsTransactions.length > 0) {
      updateRoundUpsTotals();
    }
  }, [storedRoundUpsTransactions]);

  const updateContributeNowTransactions = async () => {
    updatingPaymentDataOnContributeNowTransactionsRef.current = true;
    try {
      const updatedContributeNowTransactions = storedPayments.filter(payment => payment.paymentData.payout.description.includes('Fixed'));
      console.log('*** ** fetchPayments: updateContributeNowTransactions triggered with roundUpsTransactions.storedContributeNowTransactions:', storedContributeNowTransactions);
      if (!isEqual(updatedContributeNowTransactions,storedContributeNowTransactions) ) { 
        console.log('*** ** fetchPayments: DISPATCH - PURCHASES SET CONTRIBUTENOW TRANSACTIONS with updatedContributeNowTransactions:', updatedContributeNowTransactions);
        await  dispatch(RoundUpsTransactionsActions.setContributeNowTransactions(updatedContributeNowTransactions))
      }
    } catch(exception) {
      console.log(exception);
      log({
        event: "System-Error",
        source: "*** ** fetchPayments: Updating Contribute Now Transactions with Payment Data",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: JSON.stringify(exception),
        ...exception,
      });
    } finally {
      updatingPaymentDataOnContributeNowTransactionsRef.current = false;
    }
  };

  const updateContributeNowTotals = async () => {
    console.log('updateContributeNowTotals has commenced with storedContributeNowTransactions:', storedContributeNowTransactions)
    let totalClearing = 0;
    let totalCleared = 0;
    let totalReceived = 0;
    let totalPaid = 0;
  
    storedContributeNowTransactions.forEach(payment => {
      if (payment 
          && payment.paymentData
          && payment.paymentData.payout
          && payment.paymentData.payout.description.includes('Fixed')
          ) {
  
        if (payment.status === 'approved' || 
            payment.status === 'maturing' || 
            !payment.status) {
          totalClearing += payment.paymentData.payout.amount;
        }
        if (payment.status === 'cleared') {
          totalCleared += payment.paymentData.payout.amount;
        }
        if (payment.status === 'BYS received') {
          totalReceived += payment.paymentData.payout.amount;
        }
        if (payment.status && payment.status.includes('Paid')) {
          totalPaid += payment.paymentData.payout.amount;
        }
      }
    });
  
      console.log('Total Clearing:', totalClearing);
      console.log('Total Cleared:', totalCleared);
      console.log('Total Received:', totalReceived);
      console.log('Total Paid:', totalPaid);
      if (totalClearing !== storedContributeNowTotalClearing ||
          totalCleared !== storedContributeNowTotalCleared ||
          totalReceived !== storedContributeNowTotalReceived ||
          totalPaid !== storedContributeNowTotalPaid) {
        console.log('DISPATCH - PURCHASES SET PAYMENTS TOTALS with totalClearing:', totalClearing, 'totalCleared:', totalCleared, 'totalReceived:', totalReceived, 'totalPaid:', totalPaid)
        dispatch(RoundUpsTransactionsActions.setPaymentsTotals(totalClearing, totalCleared, totalReceived, totalPaid));
      }
    };

    const updateRoundUpsTotals = async () => {
      console.log('updateROundUpsTotals has commenced with storedROundUpsTransactions: ', storedRoundUpsTransactions);
      let totalClearing = 0;
      let totalCleared = 0;
      let totalReceived = 0;
      let totalPaid = 0;
  
      storedRoundUpsTransactions.forEach(roundUp => {
        if (roundUp
            && roundUp.debit_amount_cents
        ) {
          console.log('forEach roundUp.status:', roundUp.status)
          console.log('forEach roundUp.debit_amount_cents:', roundUp.debit_amount_cents)
          if (roundUp.status === 'pending' || !roundUp.status) {
            totalClearing += roundUp.debit_amount_cents;
          } else if (roundUp.status === 'posted' || roundUp.status === 'cleared') {
            totalCleared += roundUp.debit_amount_cents;
          } else if (roundUp.status === 'BYS received') {
            totalReceived += roundUp.debit_amount_cents;
          } else if (roundUp.status && roundUp.status.includes('Paid')) {
            totalPaid += roundUp.debit_amount_cents;
          }
        }
      });
      if (totalClearing !== storedRoundUpsTotalClearing ||
          totalCleared !== storedRoundUpsTotalCleared ||
          totalReceived !== storedRoundUpsTotalReceived ||
          totalPaid !== storedRoundUpsTotalPaid) 
          { 
              console.log('DISPATCH - PURCHASES SET ROUNDUPS TOTALS with totalClearing:', totalClearing, 'totalCleared:', totalCleared, 'totalReceived:', totalReceived, 'totalPaid:', totalPaid)   
              dispatch(RoundUpsTransactionsActions.setRoundUpsTotals(totalClearing, totalCleared, totalReceived, totalPaid));
          }
    };

  const setPaymentDataOnRoundUpsTransactions = async () => {
    updatingPaymentDataOnRoundUpsTransactionsRef.current = true;
    console.log('*** ** fetchPayments: *** setPaymentDataOnRoundUpsTransactions triggered with roundUpsTransactions:', storedRoundUpsTransactions);
    let updatedRoundUpsTransactions = [...storedRoundUpsTransactions];
  
    try {
      storedPayments.forEach(payment => {
      console.log('*** ** fetchPayments: *** setPaymentDataOnRoundUps about to asses this payment:', payment)
        if (
          payment &&
          payment.paymentData &&
          payment.paymentData.payout &&
          payment.paymentData.payout.description.includes('Roundup')
        ) {
          console.log('*** ** fetchPayments: *** setPaymentDataOnRoundUps ROUNDUP match found for payment:', payment )
          // Loop through each transaction in payment.transactions
          for (const transactionId of payment.transactions) {
            // Find the corresponding transaction in updatedRoundUpsTransactions
            const correspondingTransaction = updatedRoundUpsTransactions.find(
              transaction => transaction.id === transactionId
            );
  
            // If corresponding transaction found, update its properties
            if (correspondingTransaction) {
              console.log('*** ** fetchPayments: *** setPaymentDataOnRoundUps correspondingTransaction found:', correspondingTransaction);
              correspondingTransaction.status = payment.status;
              correspondingTransaction.withdrawalDate = payment._createdDate;
              correspondingTransaction.party_bank_ref = payment.party_bank_ref;
            }
          }
        }
      });
      console.log('*** ** fetchPayments: Updated round ups transactions:', updatedRoundUpsTransactions);
      if (updatedRoundUpsTransactions.length !== storedRoundUpsTransactions.length)  {
      //if (JSON.stringify(updatedRoundUpsTransactions) !== JSON.stringify(storedRoundUpsTransactions))  {
        console.log('*** ** fetchPayments: DISPATCH - PURCHASES SET ROUNDUPS TRANSACTIONS with updatedRoundUpsTransactions:', updatedRoundUpsTransactions);
        await dispatch(RoundUpsTransactionsActions.setRoundUpsTransactions(updatedRoundUpsTransactions));
      }
    } catch (exception) {
      console.error('Error occurred during processing:', exception);
      log({
        event: "System-Error",
        source: "*** setPaymentDataOnRoundUps",
        //'shopper_id': authentication? authentication.user.shopper_id : '000',
        info: JSON.stringify(exception),
        ...exception,
      });
    } finally {
      updatingPaymentDataOnRoundUpsTransactionsRef.current = false;
    }
  };

  return { 
          isFetchingPayments: isFetchingPaymentsRef.current,
          // hasFetchedPayments: hasFetchedPaymentsRef.current,
           fetchPayments };

};

export default useFetchPayments;
