export default ({ theme }) => {
  return`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .rdt_Table {
      border-spacing: 0;
      min-width: 100%;
      padding-top:20px;

      @media (min-width: 1023px) {
          display: none; /* Hide the table */
      }

      .rdt_TableHead {
        border-bottom: solid 2px ${theme.darkBorderColor};


        /* .rdt_TableRow {
          height: 70px;
          } */

          .rdt_TableHeadRow {
            display: flex;
            align-items: center;
            text-align: left;
            height: 100%;
            font-size: 18px;
            font-weight: bold;
            color: black;
            border: none;
            padding: 10px;
            border-bottom: 1px solid ${theme.borderColor};
            border-top: 1px solid ${theme.borderColor};
            text-transform: uppercase;
            padding: 20px 0px;
            
            .rdt_TableCol {
              min-width: unset;
              padding: 10px 5px 10px 10px;

              rdt_TableColSortable {
                padding: 5px 0px;
              }

              input{
                max-width: 120px;
                border-radius: 2px;
                padding: 5px 10px;
                font-size: unset;
              }
            }


          }
        
      }

      .rdt_TableBody {
        
        .rdt_TableRow {
          background-color: ${theme.neutralColor};
          box-shadow: 0 0 0 1px ${theme.borderColor};
          font-size: unset;
          /* padding: 20px 30px 0px 10px; */

          &:hover {
            background-color: ${theme.lightBorderColor};
          }

          .rdt_TableCell{

          

          }

          td {
            text-align: left;
            border: none;
            padding: 10px;
          }
        }
        
        .rdt_ExpanderRow{
          display: flex;
          padding: 10px;
          
          .expander-row-container{
            width: 100%;
            padding: 10px;
            margin-left:5px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            @media(min-width: 1288px) {
              width: 100%;
            }

            .account-details{
              padding: 5px;
              display: flex;
              flex-direction: column;
              

              .account-details-item{
                display: flex;
                flex-direction: row;
                padding: 5px;
                align-items: center;
                width: 100%;
                /* min-height: 50px; */

                .account-details-item-data{
                  display: flex;
                  width: 100%;
                  flex-wrap: nowrap;
                }

                .account-details-item-label{
                  font-weight: bold;
                  /* font-size: larger; */
                  display: flex;
                  width: 100%;

                  .pop-over-container{
                    display: flex;
                    p{
                      margin: unset;
                    }
                  }

                }

                .toggle-switch-holder{
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  justify-content: center;
                  width: 100%;
                  margin-right: 50px;
                }

              }

            }

            .actions-container{
              display: flex;
              flex-direction: column;

              .actions-header{

              }

              .actions-buttons{
                display: flex;
                flex-direction: row;
                padding: 10px;
                align-items: center;

                .pop-over-container{
                  p{
                    margin: unset;
                  }
                }

              }
            }
          }

        }
      }
    }


      .table-container {
        width: 100%;
        overflow: auto;
        margin: 30px 0px;

        @media (max-width: 1023px) {
          display: none; /* Hide the table */
        }

        table {
          border-spacing: 0;
          min-width: 100%;
          thead {
            border-bottom: solid 2px ${theme.lightBorderColor};
            height: 80px;

            tr {
              height: 70px;

              th {
                text-align: center;
                height: 100%;
                font-size: 18px;
                font-weight: bold;
                border: none;
                padding: 10px;
                border-bottom: 1px solid ${theme.borderColor};
                border-top: 1px solid ${theme.borderColor};
                text-transform: uppercase;

                .pop-over-container{
                  display: inline-block;
                  vertical-align: bottom;
                  p{
                    margin: unset;
                  }
                }
              }
            }
          }

          tbody {
            tr {
              background-color: ${theme.neutralColor};
              box-shadow: 0 0 0 1px ${theme.borderColor};
              height: 60px;

              &:hover {
                background-color: ${theme.lightBorderColor};
              }

              td {
                text-align: -webkit-center;;
                border: none;
                padding: 10px;
                
                .borrowing-account {
                  display: flex;
                  align-content: center;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
      
    }

  `;
};